var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{on:{"click":_vm.generateEmails}},[_vm._v("Emails")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.accounts,"items-per-page":100,"search":_vm.search,"footer-props":_vm.footer},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/admin/teams/" + (item.id))}},[_vm._v(_vm._s(item.name))])]}},{key:"item.allTeamsPaid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numberTeams ? (item.allTeamsPaid ? "Ja" : "Nee") : ""))]}},{key:"item.allTeamsComplete",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numberTeams ? (item.allTeamsComplete ? "Ja" : "Nee") : ""))]}},{key:"item.emailSubscribe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailSubscribe ? "Ja" : "Nee"))]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role == "Admin" ? "Ja" : "Nee")+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.toggleDialog(item)}}},[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}])}),_c('account-dialog',{attrs:{"account":_vm.editAccount,"show-dialog":_vm.showDialog},on:{"close":function($event){_vm.showDialog = false},"save":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }