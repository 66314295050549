import { userService } from "@/services";
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Wachtwoord reset",
  },
})
export default class PasswordRequest extends Vue {
  email = "";

  submitted = false;

  onSubmit() {
    this.submitted = true;
    userService
      .forgotPassword(this.email)
      .then((result) => {
        if (result) {
          store.dispatch("alert/success", "Wachtwoord reset e-mail is verstuurd.", { root: true });
        } else {
          store.dispatch("alert/error", "Niet gelukt om wachtwoord reset te versturen", {
            root: true,
          });
        }
      })
      .finally(() => (this.submitted = false));
  }
}
