import { News } from '@/models/news.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';


export const newsService = {
    edit,
    getNews,
    deleteNews
};

function edit(news: News) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(news)
    };

    return apiCall(`${config.apiUrl}/News`, requestOptions);
}

function getNews() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return apiCall(`${config.apiUrl}/News`, requestOptions);
}

function deleteNews(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },

    };

    return apiCall(`${config.apiUrl}/News/${id}`, requestOptions);
}
