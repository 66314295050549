import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';
import { PlayRound } from '@/models/playRound.model';

export const playRoundService = {
    get,
    getById,
    edit,
    deleteRound,
    getLastCalculated,
    isBusy
};

function edit(playRound: PlayRound) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(playRound)
    };

    return apiCall(`${config.apiUrl}/playRounds`, requestOptions);
}

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/playRounds`, requestOptions);
}

function getById(id: number) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/playRounds/${id}`, requestOptions);
}

function deleteRound(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/playRounds/${id}`, requestOptions);
}

function getLastCalculated() {
    const requestOptions = {
        method: 'GET'
    };

    return apiCall(`${config.apiUrl}/playRounds/lastCalculated`, requestOptions);

}

function isBusy() {
    const requestOptions = {
        method: 'GET'
    };

    return apiCall(`${config.apiUrl}/playRounds/isBusy`, requestOptions);

}
