import { Settings } from "@/models/settings.model";
import { router } from "@/router";
import { homeService, userService } from "@/services";
import { adminService } from "@/services/admin.service";
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { convertDateTime } from "@/helpers/time-helper";
import { cupService } from "@/services/cup.service";
import priceSettings from "./price-settings.vue";
import { priceMoneyService } from "@/services/priceMoney.service";

@Component({
  metaInfo: {
    title: "Settings",
  },
  components: { priceSettings },
})
export default class SettingsPage extends Vue {
  settings: Settings = {
    startSuperElf: "",
    endSubs: "",
    startSubs: "",
    budget: 0,
    flashScoreId: "",
    lastCalculatedRound: 0,
    lastFinishedRound: 0,
    canCreateTeams: false,
    cupPrice: [],
    overalPrice: [],
    periodPrice: [],
    intro: ""
  };
  startDate = "";
  startTime = "";
  loading = false;
  loadingCalculate = false;

  async created() {
    this.loading = true;
    this.settings = await adminService.getSettings();
    this.loading = false;
  }

  onSubmit() {
    var request = { ...this.settings };

    if (this.settings.startSuperElf) {
      request.startSuperElf = convertDateTime(this.settings.startSuperElf);
      request.startSubs = convertDateTime(this.settings.startSubs);
      request.endSubs = convertDateTime(this.settings.endSubs);
    }

    adminService.setSettings(request).then((response) => {
      homeService.getSettings().then((response) => {
        store.dispatch("alert/success", "Settings zijn opgeslagen");
        store.dispatch("settings/set", response);
      });
    });
  }

  clearCache() {
    adminService.clearCache();
  }

  async calculateCup() {
    this.loadingCalculate = true;
    await priceMoneyService.calculateCup();
    this.loadingCalculate = false;
  }

  async calculatePeriod() {
    this.loadingCalculate = true;
    await priceMoneyService.calculatePeriod();
    this.loadingCalculate = false;
  }

  async calculateTotal() {
    this.loadingCalculate = true;
    await priceMoneyService.calculateTotal();
    this.loadingCalculate = false;
  }

  async draw() {
    await cupService.draw().then(() => {
      store.dispatch("alert/success", "Loting beker is klaar.");
    });
  }
}
