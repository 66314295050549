import store from "@/store";
import config from "../config";
import { authHeader } from "../helpers/auth-header";
import { apiCall } from "../helpers/service-helper";
import { Account } from "@/models/account.model";

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  refreshToken,
  forgotPassword,
  resetPassword,
  validateResetToken,
  changeAdmin,
  getEmails,
};

function login(email: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
    credentials: "include" as RequestCredentials,
  };

  return apiCall(`${config.apiUrl}/accounts/authenticate`, requestOptions).then((user) => {
    // login successful if there's a jwt token in the response
    if (user.jwtToken) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
    }

    return user;
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function register(user: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return apiCall(`${config.apiUrl}/accounts/register`, requestOptions);
}

function forgotPassword(email: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return apiCall(`${config.apiUrl}/accounts/forgot-password`, requestOptions);
}

function validateResetToken(model: { token: string }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(model),
  };

  return apiCall(`${config.apiUrl}/accounts/validate-reset-token`, requestOptions);
}

function resetPassword(model: { token: string; password: string; confirmPassword: string }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(model),
  };

  return apiCall(`${config.apiUrl}/accounts/reset-password`, requestOptions);
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return apiCall(`${config.apiUrl}/accounts`, requestOptions);
}

function getById(id: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/accounts/${id}`, requestOptions);
}

function getEmails(): Promise<{ emails: string }> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return apiCall(`${config.apiUrl}/accounts/emails`, requestOptions);
}

function update(account: Account) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(account),
  };

  return fetch(`${config.apiUrl}/accounts/${account.id}`, requestOptions);
}

function changeAdmin(id: number, isAdmin: boolean) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: null,
  };

  return fetch(`${config.apiUrl}/accounts/admin/${id}/${isAdmin}`, requestOptions);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id: any) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return apiCall(`${config.apiUrl}/accounts/${id}`, requestOptions);
}

function refreshToken() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
  };

  //  @ts-ignore
  return apiCall(`${config.apiUrl}/accounts/refresh-token`, requestOptions).then((user) => {
    // login successful if there's a jwt token in the response
    if (user.jwtToken) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
    }
  });
}
