import { canChangeTeam, superElfStarted } from '@/helpers/time-helper';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';

@Component({
    computed: {
        ...mapState("account", ["user"])
    }
})
export default class NavMenu extends Vue {
    @Prop({ required: true }) drawer!: boolean;

    right = null;
    drawerToggle: boolean | null = null;
    public user!: any

    @Watch("drawer")
    onDrawerChange() {
        this.drawerToggle = !this.drawerToggle
    }

    get isAdmin() {
        return this.user?.role === "Admin"
    }

    get isStarted() {
        return superElfStarted();
    }

    get isChangeStarted() {
        return canChangeTeam();
    }
}