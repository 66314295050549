import { MatchPlayerDetail } from "@/models/matchPlayerDetail.model";
import { matchDetailService } from "@/services/matchDetail.service";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class PlayerDetail extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) playerDetail!: MatchPlayerDetail;
  @Prop({ required: true }) matchId!: number;

  get playerDetailEdit() {
    return { ...this.playerDetail, matchId: this.matchId };
  }
  closeDialog() {
    this.$emit("close");
  }

  async save() {
    await matchDetailService.edit(this.playerDetailEdit);
    this.$emit("save");
  }
}
