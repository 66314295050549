import { Club } from "@/models/club.model";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ClubIcon extends Vue {
  @Prop({ required: true }) width!: number;
  @Prop({ required: true }) name!: string;

  get maxHeight() {
    return Math.round(this.width * 1.25);
  }
}
