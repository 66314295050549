import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';


export const teamScoreService = {
    calculate,
    getScores,
    getTeamScore
};

function calculate(playRoundId: number) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify("")
    };

    return apiCall(`${config.apiUrl}/TeamScore/${playRoundId}`, requestOptions);
}

function getScores() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return apiCall(`${config.apiUrl}/TeamScore/scores`, requestOptions);
}

function getTeamScore(roundNumber: number, teamId: number) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    };

    return apiCall(`${config.apiUrl}/TeamScore/scores/${teamId}/${roundNumber}`, requestOptions);
}
