import { Match } from '@/models/match.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';

export const matchService = {
    get,
    edit,
    deleteMatch,
    addEdit
};

function edit(match: Match) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(match)
    };

    return apiCall(`${config.apiUrl}/match`, requestOptions);
}

function addEdit(matches: Match[]) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(matches)
    };

    return apiCall(`${config.apiUrl}/match/addEdit`, requestOptions);
}

function get(id: number) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/match/${id}`, requestOptions);
}

function deleteMatch(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/match/${id}`, requestOptions);
}
