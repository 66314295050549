import store from '@/store';

export function superElfStarted() {
  //@ts-ignore
  var start = store.state.settings.settings.startSuperElf;
  if (start) {
    var startDate = new Date(start);

    return startDate < new Date();
  }

  return false;
}

export function canChangeTeam() {
  //@ts-ignore
  var start = store.state.settings.settings.startSubs;
  //@ts-ignore
  var end = store.state.settings.settings.endSubs;
  if (start) {
    var startDate = new Date(start);
    var beforeEnd = true;
    if (end) {
      beforeEnd = new Date() < new Date(end);
    }

    return startDate < new Date() && beforeEnd;
  }

  return false;
}

export function superElfStartedInThreeDays() {
  if (superElfStarted()) {
    return false;
  }
  //@ts-ignore
  var start = store.state.settings.settings.startSuperElf;
  if (start) {
    var startDate = new Date(start);
    startDate.setDate(startDate.getDate() - 3);

    return startDate < new Date();
  }

  return false;
}

export function convertDateTime(date: string) {
  const dateTimeParts = date.split(' ');
  const time = dateTimeParts[1];
  const dateParts = dateTimeParts[0].split('-');

  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${time}`
}