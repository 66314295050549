import { Component, Vue } from "vue-property-decorator";
import { priceMoneyService } from "@/services/priceMoney.service";
import { AccountMoney } from "@/models/accountMoney.model";

@Component({
  metaInfo: {
    title: "Account winnaars",
  },
})
export default class AccountMoneyOverview extends Vue {
  loaded = false;
  prices: AccountMoney[] = [];
  search = "";
  expanded = [];

  headers = [
    {
      text: "Account",
      value: "accountName",
    },
    {
      text: "Totaal",
      value: "amount",
    },
  ];

  async created() {
    this.prices = await priceMoneyService.getAccountmoney();
    this.loaded = true;
  }

  displayMoney(money: number) {
    return `${money.toFixed(2).replace(".", ",")}`;
  }
}
