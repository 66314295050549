import { Team } from "../../models/team.model";
import { Component, Vue } from "vue-property-decorator";
import { teamService } from "../../services/team.service";
import { router } from "@/router";
import { canChangeTeam, superElfStarted, superElfStartedInThreeDays } from "@/helpers/time-helper";
import store from "@/store";
import { mapState } from "vuex";
import { Account } from "@/models/account.model";
import { account } from "@/store/account.module";

@Component({
  computed: {
    ...mapState("account", ["user"]),
  },
  metaInfo: {
    title: "Teams",
  },
})
export default class Teams extends Vue {
  teams: Team[] = [];
  public user!: Account;

  test = "";
  addMode = false;
  name = "";
  teamName = "";
  loaded = false;
  dialog = false;

  deleteId = 0;
  deleteName = "";
  start = "";
  admin = false;

  async created() {
    if (this.$route.params.id) {
      this.teams = await teamService.getAllById(+this.$route.params.id);
      this.admin = true;
    } else {
      this.teams = await teamService.get();
    }
    this.loaded = true;
    //@ts-ignore
    const startDate = new Date(store.state.settings.settings.startSuperElf);
    this.start = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()} ${String(startDate.getHours()).padStart(2, "0")}:${String(
      startDate.getMinutes()
    ).padStart(2, "0")}`;
  }

  get allPaid() {
    return this.teams.every((t) => t.paid);
  }

  get isExcluded() {
    return this.teams.some((t) => t.excluded);
  }

  showDialog(team: Team) {
    this.deleteId = team.id;
    this.deleteName = team.teamName;
    this.dialog = true;
  }

  changePaid(team: Team) {
    teamService.setPaid({ id: team.id, paid: team.paid });
  }

  addTeam() {
    this.addMode = true;
  }

  closeAddTeam() {
    this.addMode = false;
  }

  onSubmit() {
    teamService
      .create({
        playerName: this.name,
        teamName: this.teamName,
        id: 0,
        paid: false,
        accountId: this.admin ? +this.$route.params.id : 0,
      })
      .then((teams: Team[]) => {
        this.addMode = false;
        this.teams = teams;
      });
  }

  get isSuperElfStarted() {
    return superElfStarted();
  }

  get isSuperElfAlmostStarted() {
    return superElfStartedInThreeDays();
  }

  get canCreateTeams() {
    //@ts-ignore
    return store.state.settings.settings.canCreateTeams;
  }

  editTeam(id: number) {
    router.push(`/teams/edit/${id}`);
  }

  subTeam(id: number) {
    router.push(`/teams/wissel/${id}`);
  }

  async deleteTeam() {
    this.teams = await teamService.deleteTeam(this.deleteId);
    this.dialog = false;
  }

  get canChangeTeam() {
    return canChangeTeam();
  }

  goBack() {
    router.back();
  }
}
