import Vue from "vue";
import Vuex from "vuex";

import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './users.module';
import { players } from './players.module';
import { backend } from './backend.module';
import { settings } from './settings.module';
import { team } from './team.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    account,
    users,
    players,
    backend,
    settings,
    team
  }
});
