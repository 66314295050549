import { TeamPlayers } from '@/models/teamPlayers.model';
import store from '@/store';

export function priceInMil(price: number) {
  return price.toLocaleString("nl-nl");
}

export function budgetLeft(team: TeamPlayers) {

  let price = 0;

  //@ts-ignore
  var budget = store.state.settings.settings.budget;

  team.players.forEach(p => price = price + p.price);

  return budget - price;
}