import { authHeader } from "@/helpers/auth-header";
import { Player } from "@/models/player.model";
import config from "../config";
import { apiCall } from "../helpers/service-helper";

export const playerService = {
  get,
  edit,
  getByClub,
  getNotLinked,
  getDetail,
  deletePlayer,
};

function get() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return apiCall(`${config.apiUrl}/players`, requestOptions);
}

function getByClub(round: number, clubId: number) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return apiCall(`${config.apiUrl}/players/club/${round}/${clubId}`, requestOptions);
}

function getNotLinked(round: number) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return apiCall(`${config.apiUrl}/players/notlinked/${round}`, requestOptions);
}

function edit(player: Player) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), ...{ "Content-Type": "application/json" } },
    body: JSON.stringify(player),
  };

  return apiCall(`${config.apiUrl}/players`, requestOptions);
}

function deletePlayer(playerId: number) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), ...{ "Content-Type": "application/json" } },
  };

  return apiCall(`${config.apiUrl}/players/${playerId}`, requestOptions);
}

function getDetail(id: number) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return apiCall(`${config.apiUrl}/players/detail/${id}`, requestOptions);
}
