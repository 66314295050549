import { Settings } from '@/models/settings.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';

export const dashboardService = {
  get
};

function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiCall(`${config.apiUrl}/dashboard`, requestOptions);
}
