import { Position } from '@/helpers/enums';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class PositionIcon extends Vue {
  @Prop({ required: true }) position!: Position;

  get positionIcon() {
    switch (this.position) {
      case Position.Keeper:
        return "mdi-alpha-k-circle"
      case Position.Defender:
        return "mdi-alpha-v-circle"
      case Position.Midfielder:
        return "mdi-alpha-m-circle"
      case Position.Attacker:
        return "mdi-alpha-a-circle"
    }
  }

  get positionColor() {
    switch (this.position) {
      case Position.Keeper:
        return "green"
      case Position.Defender:
        return "orange"
      case Position.Midfielder:
        return "blue"
      case Position.Attacker:
        return "red"
    }
  }
}