import { Settings } from '@/models/settings.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';

export const adminService = {
  playersUpload,
  delete: _delete,
  getSettings,
  setSettings,
  clearCache
};

function playersUpload(file: any) {
  let formData = new FormData();

  formData.append('file', file);
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: formData
  };

  return apiCall(`${config.apiUrl}/admin/playersUpload`, requestOptions);
}

function _delete() {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return apiCall(`${config.apiUrl}/admin/players`, requestOptions);
}

function getSettings() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiCall(`${config.apiUrl}/admin/settings`, requestOptions);
}

function setSettings(settings: Settings) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    body: JSON.stringify(settings)
  };

  return apiCall(`${config.apiUrl}/admin/settings`, requestOptions);
}

function clearCache() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiCall(`${config.apiUrl}/admin/clearCache`, requestOptions);
}
