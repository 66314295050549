import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";
import { userService } from "@/services";
import { mapState } from "vuex";
import { Account } from "@/models/account.model";
import accountDialog from "./account-dialog/account-dialog.vue";

@Component({
  computed: {
    ...mapState("account", ["user"]),
  },
  metaInfo: {
    title: "Accounts",
  },
  components: {
    accountDialog,
  },
})
export default class Accounts extends Vue {
  accounts: Account[] = [];
  loaded = false;
  search = "";
  showDialog = false;
  public user!: Account;
  editAccount: Account | null = null;

  headers = [
    {
      text: "Naam",
      align: "start",
      value: "name",
    },
    { text: "Email", value: "email" },
    { text: "Aantal teams", value: "numberTeams" },
    { text: "Betaald", value: "allTeamsPaid" },
    { text: "Compleet", value: "allTeamsComplete" },
    { text: "Emails", value: "emailSubscribe" },
    { text: "Admin", value: "role" },
    { text: "", value: "edit" },
  ];
  footer = {
    itemsPerPageOptions: [10, 100, -1],
  };

  async created() {
    this.accounts = await userService.getAll();
    this.loaded = true;
  }

  async refresh() {
    this.showDialog = false;
    this.accounts = await userService.getAll();
  }

  toggleDialog(account: Account) {
    this.editAccount = account;
    this.showDialog = true;
  }

  adminDisabled(account: Account) {
    return (
      this.user.email.toLocaleLowerCase() !== "mp_mul@hotmail.com" ||
      account.email.toLocaleLowerCase() === "mp_mul@hotmail.com"
    );
  }

  async generateEmails() {
    const emails = await userService.getEmails();
    console.log(emails);
    navigator.clipboard.writeText(emails.emails);
  }
}
