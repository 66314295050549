import { Cup } from "@/models/cup.model";
import { cupService } from "@/services/cup.service";
import { playRoundService } from "@/services/playRound.service";
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("account", ["user"]),
  },
  metaInfo: {
    title: "Beker",
    meta: [
      { vmid: "description", name: "description", content: "De beker van de Beround Superelf" },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class CupOverview extends Vue {
  cup: Cup = {} as Cup;
  public user!: any;
  loaded = false;
  lastCalculated = 0;
  search = "";

  async created() {
    this.cup = await cupService.get();
    this.lastCalculated = await playRoundService.getLastCalculated();
    this.loaded = true;
  }

  async getCupPerRound(roundNumber: number) {
    this.cup = await cupService.getPerRound(roundNumber);
  }

  get filteredMatches() {
    if (this.search) {
      return this.cup.matches.filter(
        (m) =>
          m.homeTeam.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          m.awayTeam.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    }

    return this.cup.matches;
  }

  get freeRoundMatches() {
    return this.filteredMatches.filter((m) => !m.awayTeamId);
  }

  get cupMatches() {
    return this.filteredMatches.filter((m) => m.awayTeamId && !m.replay);
  }

  replayMatch(homeTeamId: number) {
    return this.cup.matches.filter((c) => c.replay && c.homeTeamId == homeTeamId && c.result !== 0);
  }

  replayResult(homeTeamId: number) {
    if (this.cup.matches.some((m) => m.result == 1 && m.homeTeamId == homeTeamId)) {
      return 1;
    }

    if (this.cup.matches.some((m) => m.result == 2 && m.homeTeamId == homeTeamId)) {
      return 2;
    }
  }

  get teamPlayround() {
    return this.cup.round === 0 || this.cup.round > this.lastCalculated
      ? this.lastCalculated
      : this.cup.round;
  }

  teamRoundNumber(teamId: number) {
    const matches = this.cup.matches.filter(
      (m) => m.homeTeamId === teamId || m.awayTeamId === teamId
    );
    const maxRound = Math.max(...matches.map((o) => o.roundNumber));

    return maxRound === 0 || maxRound > this.lastCalculated ? this.lastCalculated : maxRound;
  }
}
