import { Component, Vue } from "vue-property-decorator";
import { teamScoreService } from "@/services/teamScore.service";
import { TeamRoundScore } from "@/models/teamRoundPoints";
import { orderDetail } from "@/models/player.model";
import playerRoundInfo from "./info/player-round-info.vue";
import { Position } from "@/helpers/enums";
import { router } from "@/router";
import store from "@/store";
import { canChangeTeam } from "@/helpers/time-helper";
import { mapState } from "vuex";
import { Account } from "@/models/account.model";
import { priceInMil } from "@/helpers/price-helper";
import { favoriteService } from "@/services/favorite.service";

@Component({
  components: { playerRoundInfo },
  computed: {
    ...mapState("account", ["user"]),
  },
  metaInfo() {
    return {
      //@ts-ignore
      title: this.score.teamName ? `${this.score.teamName} | Punten` : "",
      meta: [{ name: "robots", content: "noindex, nofollow" }],
    };
  },
})
export default class TeamRoundPoints extends Vue {
  loaded = false;
  round = 0;
  teamId = 0;
  score: TeamRoundScore = {} as TeamRoundScore;
  lastRound = 0;
  backHome = false;
  favorite = false;

  public user!: Account;

  async created() {
    this.teamId = +this.$route.params.teamId;
    const roundRoute = +this.$route.params.round;

    this.round = roundRoute ? roundRoute : 0;

    this.score = await teamScoreService.getTeamScore(this.round, this.teamId);

    this.round = this.score.round;

    if (this.$route.params.home) {
      this.backHome = true;
    }

    this.loaded = true;

    const favorites = favoriteService.getFavorites();

    this.favorite = favorites.includes(this.teamId);
  }

  get startPlayers() {
    return this.score.scores.filter((b) => !b.bank).sort(orderDetail);
  }

  get bankPlayers() {
    return this.score.scores.filter((b) => b.bank).sort(orderDetail);
  }

  roundBack() {
    router.push(`/stand/team/${this.teamId}/${this.round - 1}`);
  }

  roundNext() {
    router.push(`/stand/team/${this.teamId}/${this.round + 1}`);
  }

  standings() {
    router.push(`/stand`);
  }

  canChange() {
    return canChangeTeam() && this.score.userTeam;
  }

  goBack() {
    this.$router.back();
  }

  get displayBudget() {
    return priceInMil(this.score.teamValue);
  }

  toggleFavorite() {
    this.favorite = !this.favorite;
    if (this.favorite) {
      favoriteService.addFavorite(this.teamId);
    } else {
      favoriteService.deleteFavorite(this.teamId);
    }
  }
}
