import { mapState } from "vuex";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Account } from '@/models/account.model';
import { TileStanding } from '@/models/homeTiles.model';
import clubIcon from '../clubs/club-icon.vue';

@Component({
  computed: {
    ...mapState("account", ["user"])
  },
  metaInfo: {
    title: "Home"
  },
  components: {
    clubIcon
  }
})
export default class HomeTile extends Vue {
  @Prop({ required: true }) standing!: TileStanding[];
  @Prop({ required: false, default: "" }) overviewLink!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) round!: number;
  @Prop({ required: false, default: false }) playerDetail!: boolean;

  public user!: Account
}