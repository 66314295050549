import { mapState } from "vuex";
import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";
import { superElfStarted } from "@/helpers/time-helper";
import store from "@/store";
import { Account } from "@/models/account.model";
import { HomeTiles } from "@/models/homeTiles.model";
import { homeService } from "@/services";
import homeTile from "./HomeTile.vue";
import cupTile from "./CupTile.vue";
import { favoriteService } from "@/services/favorite.service";

@Component({
  components: {
    homeTile,
    cupTile,
  },
  computed: {
    ...mapState("account", ["user"]),
  },
  metaInfo: {
    title: "Home",
  },
})
export default class Homepage extends Vue {
  public user!: Account;
  public tiles: HomeTiles = {} as HomeTiles;
  public loaded = false;

  async created() {
    const favorites = favoriteService.getFavorites();
    this.tiles = await homeService.getTiles(favorites);
    this.loaded = true;
  }

  get canCreateTeams() {
    //@ts-ignore
    return store.state.settings.settings.canCreateTeams;
  }

  get intro() {
    //@ts-ignore
    return store.state.settings.settings.intro;
  }

  get isStarted() {
    return superElfStarted();
  }
}
