import { orderClub, Player } from "@/models/player.model";
import playerInfo from "../info/player-info.vue";
import { playerService } from "@/services/player.service";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Position } from "@/helpers/enums";
import { router } from "@/router";
import { teamService } from "@/services/team.service";
import store from "../../../store";
import { TeamPlayers } from "@/models/teamPlayers.model";
import { budgetLeft, priceInMil } from "@/helpers/price-helper";
import { mapState } from "vuex";
//@ts-ignore
import downloadCsv from "vue-json-csv";

@Component({
  components: {
    playerInfo,
    downloadCsv,
  },
  computed: {
    ...mapState("players", ["players"]),
    ...mapState("account", ["user"]),
    ...mapState("team", {
      inputTeam: "team",
      oldClubs: "deletedPlayers",
    }),
  },
  metaInfo: {
    title: "Spelers",
    meta: [
      { vmid: "description", name: "description", content: "Spelers" },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class SearchPlayers extends Vue {
  @Prop({ required: false, default: false }) adminMode!: boolean;

  allClubs = "Alle clubs";
  possibleClubs = "Nog niet gekozen";
  allPositions: string = "Alle posities";
  allPrices: string = "Alle prijzen";
  filterClub: string | null = null;
  filterPosition: string | null = null;
  filterName = "";
  filterPrice = null;
  loaded = false;
  sortOption: number | null = null;
  busyAdding = false;
  teamId = 0;
  team: TeamPlayers | null = null;

  public inputTeam!: TeamPlayers;
  public oldClubs!: { id: number; club: string }[];

  public players!: Player[];
  public user!: any;

  nameThrottle = "";
  timeOutThrottle = 0;

  async created() {
    await store.dispatch("players/getAll");

    if (this.routePosition) {
      this.filterPosition = this.routePosition;
    }

    if (this.isSubMode) {
      this.team = this.inputTeam;
      if (!this.team?.id) {
        this.team = await teamService.getTeam(+this.$route.params.id);
        store.dispatch("team/set", this.team);
      }
      this.filterClub = this.possibleClubs;
      this.teamId = this.inputTeam.id;
    } else if (this.$route.params.id) {
      this.teamId = +this.$route.params.id;
      this.team = await teamService.getTeam(this.teamId);
      this.filterClub = this.possibleClubs;
    }
    this.loaded = true;
  }

  async add(playerId: number) {
    if (this.isSubMode) {
      const player = this.players.find((p) => p.playerId === playerId);
      if (player) {
        const teamPlayer = {
          bank: this.bank,
          position: player.position,
          price: player.price,
          club: player.club,
          id: 0,
          playerId: playerId,
          playerName: player.playerName,
        };
        store.dispatch("team/add", teamPlayer);
        router.push(this.teamLink);
      }
    } else {
      try {
        this.busyAdding = true;
        await teamService.addPlayer({ bank: this.bank, playerId, teamId: this.teamId });
        router.push(this.teamLink);
      } finally {
        this.busyAdding = false;
      }
    }
  }

  @Watch("nameThrottle")
  setNameThrottle() {
    if (this.timeOutThrottle) {
      clearTimeout(this.timeOutThrottle);
    }

    this.timeOutThrottle = setTimeout(() => {
      this.filterName = this.nameThrottle;
    }, 300);
  }

  get teamLink() {
    return `/teams/${this.isSubMode ? "wissel" : "edit"}/${this.teamId}`;
  }

  get routePosition() {
    return this.$route.params.position;
  }

  get selectedClubs() {
    return this.team?.players
      .map((p) => p.club)
      .concat(
        this.team?.players
          .filter(
            (p) =>
              p.orgclub &&
              this.team?.players.some((t) => t.club === p.club && t.playerId !== p.playerId)
          )
          .map((p) => p.orgclub!)
      );
  }

  get bank() {
    return this.$route.params.bank === "true";
  }

  get isSubMode() {
    return this.$route.path.includes("/teams/wissel");
  }

  getErrorMessage(player: Player) {
    if (!this.team) {
      return "";
    }

    if (this.selectedClubs?.some((c) => c == player.club)) {
      return "Deze club is reeds gekozen";
    } else if (budgetLeft(this.team) < player.price) {
      return "Speler past niet in budget";
    } else if (this.isSubMode) {
      const oldClub = this.oldClubs.find((o) => o.id === player.playerId);
      if (oldClub && this.selectedClubs?.some((c) => c == oldClub.club)) {
        return "Bestaande speler kan niet gekozen worden vanwege oude club";
      }
    }

    return "";
  }

  get playersCsv() {
    return this.players.filter((p) => p.price !== 0);
  }

  get playersFiltered() {
    let playersFilt = [...this.players];

    if (!this.adminMode) {
      playersFilt = playersFilt.filter((p) => p.price !== 0);
    }

    if (this.filterClub) {
      if (this.filterClub === this.possibleClubs && this.team?.players) {
        playersFilt = playersFilt.filter((p) => !this.selectedClubs?.includes(p.club));
      } else {
        playersFilt = playersFilt.filter((p) => this.filterClub === p.club);
      }
    }

    if (this.filterPosition) {
      playersFilt = playersFilt.filter((p) => p.position === this.filterPosition);
    }

    if (this.filterName) {
      const lowerFilterName = this.filterName.toLowerCase();
      playersFilt = playersFilt.filter((p) => p.playerName.toLowerCase().includes(lowerFilterName));
    }

    if (this.filterPrice) {
      if (this.filterPrice === "4+") {
        playersFilt = playersFilt.filter((p) => p.price > 4000000);
      } else if (this.filterPrice === "0") {
        playersFilt = playersFilt.filter((p) => p.price === 0);
      } else {
        const priceNumber = +this.filterPrice!;
        const lowerAmount = (priceNumber - 1) * 1000000;
        const highAmount = priceNumber * 1000000;

        playersFilt = playersFilt.filter((p) => p.price >= lowerAmount && p.price < highAmount);
      }
    }

    return playersFilt.sort((a: Player, b: Player) => {
      if (!this.sortOption) {
        return orderClub(a, b);
      } else if (this.sortOption === 2) {
        return this.orderLastYearScore(a, b);
      } else if (this.sortOption === 3) {
        return this.orderScore(a, b);
      } else {
        return this.orderPrice(a, b, this.sortOption);
      }
    });
  }

  get clubs() {
    let distinctClubs: { text: string; value: string | null }[] = [];
    distinctClubs.push({ text: this.allClubs, value: null });

    if (this.teamId) {
      distinctClubs.push({ text: this.possibleClubs, value: this.possibleClubs });
    }

    //@ts-ignore

    store.state.players.clubs.forEach((club) => {
      distinctClubs.push({ text: club, value: club });
    });

    return distinctClubs;
  }

  get positionFilter() {
    const positions = [
      { text: this.allPositions, value: null },
      { text: "Keepers", value: Position.Keeper },
      { text: "Verdedigers", value: Position.Defender },
      { text: "Middenvelders", value: Position.Midfielder },
      { text: "Aanvallers", value: Position.Attacker },
    ];
    return positions;
  }

  get priceFilter() {
    const prices = [
      { text: this.allPrices, value: null },
      { text: "0 tot 1 M", value: "1" },
      { text: "1 tot 2 M", value: "2" },
      { text: "2 tot 3 M", value: "3" },
      { text: "3 tot 4 M", value: "4" },
      { text: "meer dan 4 M", value: "4+" },
    ];

    if (this.adminMode) {
      prices.splice(1, 0, { text: "0", value: "0" });
    }
    return prices;
  }

  get sortOptions() {
    return [
      { text: "Club", value: null },
      { text: "Prijs (oplopend)", value: 1 },
      { text: "Prijs (aflopend)", value: -1 },
      { text: "Punten dit seizoen", value: 3 },
      { text: "Punten vorig seizoen", value: 2 },
    ];
  }

  get budgetLeft() {
    return this.team ? `€ ${priceInMil(budgetLeft(this.team))}` : 0;
  }

  get breadCrumbsItems() {
    return [
      {
        text: "Mijn teams",
        disabled: false,
        to: this.teamUrl,
        exact: true,
      },
      {
        text: this.team?.teamName,
        disabled: false,
        to: this.teamLink,
        exact: true,
      },
      {
        text: "Selecteer speler",
        disabled: true,
        to: "edit/players",
        exact: true,
      },
    ];
  }

  get teamUrl() {
    if (!this.team || this.user.id === this.team.accountId) {
      return "/teams";
    } else {
      return `/admin/teams/${this.team.accountId}`;
    }
  }

  private orderPrice(a: Player, b: Player, ascending: number) {
    var dif = (a.price - b.price) * ascending;

    if (dif === 0) {
      return orderClub(a, b);
    } else {
      return dif;
    }
  }

  private orderLastYearScore(a: Player, b: Player) {
    var dif = b.lastYearScore - a.lastYearScore;

    if (dif === 0) {
      return orderClub(a, b);
    } else {
      return dif;
    }
  }

  private orderScore(a: Player, b: Player) {
    var dif = (b.score ?? 0) - (a.score ?? 0);

    if (dif === 0) {
      return orderClub(a, b);
    } else {
      return dif;
    }
  }
}
