var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"cup-overview"},[(_vm.cup.matches.length === 0)?_c('div',[_vm._v("De beker is nog niet gestart")]):[_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('h2',[_vm._v("Bekerronde "+_vm._s(_vm.cup.cupRound))]),(_vm.cup.round)?_c('h3',[_vm._v("Speelronde "+_vm._s(_vm.cup.round))]):_vm._e(),_vm._l((_vm.cupMatches),function(match,index){return _c('div',{key:index,staticClass:"d-flex justify-space-between cup-overview__match"},[_c('div',{staticClass:"d-flex flex-column mr-2 mb-4"},[_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"black--text",class:{
              'font-weight-bold':
                match.result === 1 ||
                (match.result === 3 && _vm.replayResult(match.homeTeamId) === 1),
            },attrs:{"to":("/stand/team/" + (match.homeTeamId) + "/" + (_vm.teamRoundNumber(
              match.homeTeamId
            )))}},[_vm._v(_vm._s(("" + (match.homeTeam))))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(match.homePosition)),_c('sup',[_vm._v("e")])])],1),_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"black--text",class:{
              'font-weight-bold':
                match.result === 2 ||
                (match.result === 3 && _vm.replayResult(match.homeTeamId) === 2),
            },attrs:{"to":("/stand/team/" + (match.awayTeamId) + "/" + _vm.teamPlayround)}},[_vm._v(_vm._s(("" + (match.awayTeam))))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(match.awayPosition)),_c('sup',[_vm._v("e")])])],1)]),_c('div',{staticClass:"d-flex text-right"},[(match.result !== 0)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(match.homeScore))]),_c('span',[_vm._v(_vm._s(match.awayScore))])]):_vm._e(),_vm._l((_vm.replayMatch(match.homeTeamId)),function(replay,index){return (match.result === 3)?_c('div',{key:'r' + index,staticClass:"d-flex flex-column ml-6"},[_c('span',[_vm._v(_vm._s(replay.homeScore))]),_c('span',[_vm._v(_vm._s(replay.awayScore))])]):_vm._e()})],2)])}),(_vm.freeRoundMatches.length > 0)?[_c('h2',[_vm._v("Door zonder te spelen")]),_vm._l((_vm.freeRoundMatches),function(match,index){return _c('div',{key:'f' + index,staticClass:"cup-overview__match"},[_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"black--text",attrs:{"to":("/stand/team/" + (match.homeTeamId) + "/" + _vm.teamPlayround)}},[_vm._v(_vm._s(((match.homeTeam) + " ")))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(match.homePosition)),_c('sup',[_vm._v("e")])])],1)])})]:_vm._e()],_c('v-footer',{staticClass:"pt-2 pb-2 sticky-footer",attrs:{"app":"","bottom":"","padless":"","color":"white"}},[_c('div',[(_vm.cup.cupRound > 1)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.getCupPerRound(_vm.cup.cupRound - 1)}}},[_vm._v("< Ronde "+_vm._s(_vm.cup.cupRound - 1))]):_vm._e(),(_vm.cup.cupRound < _vm.cup.lastCupRound)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.getCupPerRound(_vm.cup.cupRound + 1)}}},[_vm._v("Ronde "+_vm._s(_vm.cup.cupRound + 1)+" > ")]):_vm._e()],1)])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }