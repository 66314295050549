import { Position } from "@/helpers/enums";
import { Account } from "@/models/account.model";
import { userService } from "@/services";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("account", ["user"]),
  },
})
export default class PlayerDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) account!: Account;

  public clubs!: string[];
  public user!: Account;

  closeDialog() {
    this.$emit("close");
  }

  adminDisabled(account: Account) {
    return (
      this.user.email.toLocaleLowerCase() !== "mp_mul@hotmail.com" ||
      account.email.toLocaleLowerCase() === "mp_mul@hotmail.com"
    );
  }

  async save() {
    await userService.update(this.account);
    this.$emit("save");
  }

  clickAdmin(isAdmin: boolean) {
    this.account.role = isAdmin ? "Admin" : "User";
  }
}
