export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user') as any);

    if (user && user.jwtToken) {
        return { 'Authorization': 'Bearer ' + user.jwtToken };
    } else {
        return [];
    }
}

export function refreshHeader(headers: any) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user') as any);

    if (user && user.jwtToken) {
        headers["Authorization"] = 'Bearer ' + user.jwtToken;
    }
}