import { Component, Vue } from "vue-property-decorator";
import { News } from "@/models/news.model";
import { newsService } from "@/services/news.service";

@Component({
  metaInfo: {
    title: "Nieuws",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Nieuws van de Beround Superelf.",
      },
      { name: "robots", content: "nofollow" },
    ],
  },
  components: {},
})
export default class NewsPlayerOverview extends Vue {
  loaded = false;
  news: News[] = [];

  get headersNews() {
    var headers = [
      {
        text: "Nieuws berichten",
        value: "text",
      },
    ];

    return headers;
  }

  async created() {
    this.news = await newsService.getNews();
    this.loaded = true;
  }
}
