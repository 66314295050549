import { Position } from "@/helpers/enums";
import { MatchPlayerDetail } from "./matchPlayerDetail.model";

export interface Player {
  club: string;
  playerName: string;
  firstName: string;
  lastName: string;
  position: Position;
  price: number;
  playerId: number;
  round?: number;
  score?: number;
  lastYearScore: number;
}

export function orderClub(a: Player, b: Player) {
  const clubA = a.club.toLowerCase();
  const clubB = b.club.toLowerCase();
  if (clubA < clubB) {
    return -1;
  }
  if (clubA > clubB) {
    return 1;
  }

  if (positionOrder[a.position] < positionOrder[b.position]) {
    return -1;
  }

  if (positionOrder[a.position] > positionOrder[b.position]) {
    return 1;
  }

  if (a.playerName < b.playerName) {
    return -1;
  }
  if (a.playerName > b.playerName) {
    return 1;
  }

  return 0;
}

export function orderDetail(
  a: { position: Position; playerName: string },
  b: { position: Position; playerName: string }
) {
  if (positionOrder[a.position] < positionOrder[b.position]) {
    return -1;
  }

  if (positionOrder[a.position] > positionOrder[b.position]) {
    return 1;
  }

  if (a.playerName < b.playerName) {
    return -1;
  }
  if (a.playerName > b.playerName) {
    return 1;
  }

  return 0;
}

const positionOrder = {
  [Position.Keeper]: 0,
  [Position.Defender]: 1,
  [Position.Midfielder]: 2,
  [Position.Attacker]: 3,
};
