import { Component, Vue } from "vue-property-decorator";
import { TeamTotalPointsModel, TeamsTotalRoundScore } from "@/models/teamPoints";
import { teamScoreService } from "@/services/teamScore.service";
import { router } from "@/router";

@Component({
  metaInfo: {
    title: "Totaal punten",
    meta: [
      { vmid: "description", name: "description", content: "De stand van de Beround Superelf" },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class TeamTotalPoints extends Vue {
  loaded = false;
  scores: TeamsTotalRoundScore = {} as TeamsTotalRoundScore;
  search = "";
  scoreView = "";

  async created() {
    this.scores = await teamScoreService.getScores();
    this.loaded = true;
    if (this.$route.params.round == "ronde") {
      this.scoreView = "round";
    }

    if (this.$route.params.round == "periode") {
      this.scoreView = `period${this.scores.period}`;
    }
  }

  get headers() {
    const headers = [];

    headers.push({
      text: "Positie",
      value: "position",
      width: "50px",
      align: "end",
      cellClass: "font-weight-bold",
    });

    if (this.showScoreDetails) {
      headers.push({
        text: "Vorige Positie",
        value: "previousPosition",
        width: "60px",
        align: "center",
      });
    }

    headers.push({
      text: "Team",
      value: "teamName",
      cellClass: "link-cell",
    });

    headers.push({
      text: "Punten totaal",
      value: "score",
      align: "end",
      width: "50px",
      cellClass: "blue--text",
    });

    if (this.showScoreDetails) {
      headers.push({
        text: "Punten ronde",
        value: "scoreRound",
        align: "end",
        width: "50px",
      });
    }

    return headers;
  }

  handleClick(roundScore: TeamTotalPointsModel) {
    router.push(`/stand/team/${roundScore.teamId}`);
  }

  get tableItems() {
    if (this.scoreView === "total") {
      return this.scores.scores;
    } else if (this.scoreView === "round") {
      return [...this.scores.scores].sort(this.orderRound);
    } else if (this.scoreView === "period1") {
      return this.periodScore(1);
    } else if (this.scoreView === "period2") {
      return this.periodScore(2);
    } else if (this.scoreView === "period3") {
      return this.periodScore(3);
    }
  }

  get highScore() {
    return Math.max(...this.scores.scores.map((o) => o.scoreRound));
  }

  get showScoreDetails() {
    return this.scoreView === "total" || this.scoreView === "round";
  }

  periodScore(period: number) {
    return this.scores.scores
      .map((s) => ({
        position: s.positionPeriod[period - 1],
        teamName: s.teamName,
        score: s.scorePeriod[period - 1],
        teamId: s.teamId,
      }))
      .sort((a, b) => a.position - b.position);
  }

  get scoreItems() {
    var items = [
      { value: "total", text: "Totaal" },
      { value: "round", text: "Ronde" },
    ];

    if (this.scores.period >= 1) {
      items.push({ value: "period1", text: "1e periode" });
    }

    if (this.scores.period >= 2) {
      items.push({ value: "period2", text: "2e periode" });
    }

    if (this.scores.period >= 3) {
      items.push({ value: "period3", text: "3e periode" });
    }

    return items;
  }

  orderRound(a: TeamTotalPointsModel, b: TeamTotalPointsModel) {
    if (a.scoreRound < b.scoreRound) {
      return 1;
    }

    if (a.scoreRound > b.scoreRound) {
      return -1;
    }

    if (a.score < b.score) {
      return 1;
    }
    if (a.score > b.score) {
      return -1;
    }

    return 0;
  }
}
