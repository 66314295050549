import { Player } from '@/models/player.model';
import positionIcon from "../position-icon/position-icon.vue"
import { Component, Prop, Vue } from "vue-property-decorator";
import { priceInMil } from '@/helpers/price-helper';
import { superElfStarted } from '@/helpers/time-helper';
import clubIcon from '@/components/clubs/club-icon.vue';

@Component({
  components: {
    positionIcon,
    clubIcon
  }
})
export default class PlayerInfo extends Vue {
  @Prop({ required: true }) player!: Player;
  @Prop({ required: false, default: false }) addMode!: boolean;
  @Prop({ required: false, default: "" }) errorMessage!: string;
  @Prop({ required: false, default: false }) adminMode!: boolean;
  @Prop({ required: false, default: false }) busyAdding!: boolean;


  get getPriceInMil() {
    return priceInMil(this.player.price);
  }

  add() {
    this.$emit("add", this.player.playerId);
  }

  editPlayer() {
    this.$emit("edit", this.player);
  }

  get isStarted() {
    return superElfStarted();
  }
}