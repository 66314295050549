import { userService } from '@/services';
import store from "../store"
import { refreshHeader } from './auth-header';

export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

export function apiCall(url: string, request: RequestInit, retry = false) {
    store.dispatch("backend/start");
    return fetch(url, request)
        .then(response => handleResponse(response, url, request, retry))
        .finally(() => store.dispatch("backend/end"));
}

export function handleResponse(response: any, url: string, request: RequestInit, retry: boolean) {
    store.dispatch("alert/clear");
    return response.text().then(async (text: any) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (url.includes("refresh-token")) {
                logout();
                window.location.href = "/login";
            }

            if (response.status === 401) {
                // auto logout if 401 response returned from api
                if (!retry && !url.includes("refresh-token")) {
                    await userService.refreshToken();
                    refreshHeader(request.headers);
                    return apiCall(url, request, true);
                }
                else {
                    logout();
                    window.location.href = "/login";
                    return Promise.reject();
                }
            }

            const error = (data && data.message) || response.statusText;
            if (!data || !data.message) {
                store.dispatch("alert/error", "Er is iets misgegaan, probeer later opnieuw.");
            } else {
                store.dispatch("alert/error", error);
            }

            return Promise.reject(error);
        }
        return data;
    });
}
