import { teamService } from '@/services';
import { adminService } from '@/services/admin.service';
import store from '@/store';
import { Component, Vue } from "vue-property-decorator";
import searchPlayers from "../search/search-players.vue";
import playerDialog from "../edit/player-dialog/player-dialog.vue"
import { Player } from '@/models/player.model';

@Component({
  components: {
    searchPlayers,
    playerDialog
  }
})
export default class EditPlayers extends Vue {

  file = {};
  hasPlayers = false;
  loading = true;
  anyTeams = false;
  showDialog = false;
  editplayer = {} as Player | undefined;

  async created() {
    var players = await store.dispatch("players/getAll");
    this.anyTeams = await teamService.anyTeams();

    if (players && players.length > 0) {
      this.hasPlayers = true;
    }

    this.loading = false;
  }
  upload() {
    adminService.playersUpload(this.file);
  }

  deletePlayers() {
    adminService.delete();
  }

  toggleDialog(show: boolean, player?: Player) {
    this.showDialog = show;

    if (show) {
      this.editplayer = Object.assign({}, player ?? {} as Player);
    }
  }

  async save() {
    await store.dispatch("players/getAll", { reset: true });
    this.showDialog = false;
  }
}