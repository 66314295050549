import { Message } from '@/models/message.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';


export const messageService = {
    edit,
    getMessages,
    deleteNews,
    getAdminMessages
};

function edit(message: Message) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(message)
    };

    return apiCall(`${config.apiUrl}/Message`, requestOptions);
}

function getMessages() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    };

    return apiCall(`${config.apiUrl}/Message`, requestOptions);
}

function getAdminMessages() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
    };

    return apiCall(`${config.apiUrl}/Message/admin`, requestOptions);
}

function deleteNews(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },

    };

    return apiCall(`${config.apiUrl}/Message/${id}`, requestOptions);
}
