import Teams from "../components/teams/teams.vue";
import Vue from "vue";
import Router from "vue-router";

import HomePage from "../components/home/HomePage.vue";
import EditTeam from "../components/teams/edit/edit-team.vue";
import EditPlayers from "@/components/players/edit/edit-players.vue";
import SearchPlayers from "@/components/players/search/search-players.vue";
import Login from "@/components/login/login.vue";
import Register from "@/components/register/register.vue";
import Accounts from "@/components/accounts/accounts.vue";
import SettingsPage from "@/components/settings/settings.vue";
import PlayRoundOverView from "@/components/playRound/play-round-overview.vue";
import MatchOverview from "@/components/match/match-overview.vue";
import PasswordRequest from "@/components/password/password-request/password-request.vue";
import PasswordChange from "@/components/password/password-change/password-change.vue";
import Rules from "@/components/rules/rules.vue";
import Winners from "@/components/winners/winners.vue";
import MatchDetailOverview from "@/components/matchDetail/match-detail-overview.vue";
import PlayerTotalStats from "@/components/stats/player-total-stats/player-total-stats.vue";
import TeamTotalPoints from "@/components/stats/team-total-points/team-total-points.vue";
import TeamRoundPoints from "@/components/stats/team-round-points/team-round-points.vue";
import RoundWinnerOverview from "@/components/roundWinner/round-winner-overview.vue";
import CupOverview from "@/components/cup/cup-overview.vue";
import Dashboard from "@/components/dashboard/dashboard.vue";
import NewsOverview from "@/components/news/overview/news-overview.vue";
import SubTeam from "@/components/teams/subs/sub-team.vue";
import SubOverView from "@/components/teams/subs/sub-overview.vue";
import PlayerSelectDetail from "@/components/players/player-select-detail/player-select-detail.vue";
import PriceMoneyOverview from "@/components/pricemoney/pricemoney-overview.vue";
import MessageOverview from "@/components/message/overview/message-overview.vue";
import NewsPlayerOverview from "@/components/news/overview-player/news-player-overview.vue";
import AccountMoneyOverview from "@/components/accountMoney/account-money.vue";
Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    { path: "/", component: HomePage },
    { path: "/login", component: Login },
    { path: "/wachtwoord-vergeten", component: PasswordRequest },
    { path: "/wachtwoord-aanpassen", component: PasswordChange },
    { path: "/spelregels", component: Rules },
    { path: "/eregalerij", component: Winners },
    { path: "/spelers", component: SearchPlayers },
    { path: "/register", component: Register },
    { path: "/wissels", component: SubOverView },
    { path: "/speler-stats", component: PlayerTotalStats },
    { path: "/speler/:id", component: PlayerSelectDetail },
    { path: "/stand/:round?", component: TeamTotalPoints },
    { path: "/stand/team/:teamId/:round?/:home?", component: TeamRoundPoints },
    { path: "/beker", component: CupOverview },
    { path: "/rondewinnaars", component: RoundWinnerOverview },
    { path: "/prijzengeld", component: PriceMoneyOverview },
    { path: "/nieuws", component: NewsPlayerOverview },
    { path: "/teams", component: Teams },
    { path: "/teams/edit/:id", component: EditTeam },
    { path: "/teams/edit/:id/players/:bank/:position", component: SearchPlayers },
    { path: "/teams/wissel/:id", component: SubTeam },
    { path: "/teams/wissel/:id/players/:bank/:position", component: SearchPlayers },
    { path: "/edit-players", component: EditPlayers },
    { path: "/berichten", component: MessageOverview },
    { path: "/admin/accounts", component: Accounts },
    { path: "/admin/teams/:id", component: Teams },
    { path: "/admin/settings", component: SettingsPage },
    { path: "/admin/speelrondes", component: PlayRoundOverView },
    { path: "/admin/:id/wedstrijden", component: MatchOverview },
    { path: "/admin/wedstrijd/:id", component: MatchDetailOverview },
    { path: "/admin/dashboard", component: Dashboard },
    { path: "/admin/nieuws", component: NewsOverview },
    { path: "/admin/wissels", component: SubOverView },
    { path: "/admin/uitbetaling", component: AccountMoneyOverview },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/",
    "/login",
    "/register",
    "/wachtwoord-vergeten",
    "/wachtwoord-aanpassen",
    "/speler-stats",
    "/spelregels",
    "/eregalerij",
    "/prijzengeld",
    "/rondewinnaars",
    "/spelers",
    "/beker",
    "/wissels",
    "/nieuws",
  ];
  const authRequired =
    !publicPages.includes(to.path) &&
    !to.path.startsWith("/stand") &&
    !to.path.startsWith("/speler/");
  const loggedIn = localStorage.getItem("user");
  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});
