import { Component, Prop, Vue } from "vue-property-decorator";
import { priceInMil } from '@/helpers/price-helper';
import { TeamRoundPointsModel } from '@/models/teamRoundPoints';
import positionIcon from '@/components/players/position-icon/position-icon.vue';
import clubIcon from '@/components/clubs/club-icon.vue';

@Component({
  components: {
    positionIcon,
    clubIcon
  }
})
export default class PlayerRoundInfo extends Vue {
  @Prop({ required: true }) player!: TeamRoundPointsModel;
  @Prop({ required: false, default: false }) addMode!: boolean;
  @Prop({ required: false, default: "" }) errorMessage!: string;
  @Prop({ required: false, default: false }) adminMode!: boolean;


  get getPriceInMil() {
    return priceInMil(this.player.price);
  }
}