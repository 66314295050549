import { Component, Vue } from "vue-property-decorator";
import { RounndWinner } from "@/models/roundWinner.model";
import { priceMoneyService } from "@/services/priceMoney.service";

@Component({
  metaInfo: {
    title: "Rondewinnaars",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Rondewinnaars van de Beround Superelf",
      },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class RoundwinnerOverview extends Vue {
  loaded = false;
  prices: RounndWinner[] = [];
  search = "";

  headers = [
    {
      text: "Ronde",
      value: "round",
      width: "50px",
      align: "end",
      cellClass: "font-weight-bold",
    },
    {
      text: "Team",
      value: "teamName",
    },
  ];

  async created() {
    this.prices = await priceMoneyService.getRoundWinner();
    this.loaded = true;
  }
}
