import { MatchDetail } from '@/models/matchDetail.model';
import { MatchEventResult } from '@/models/matchEventResult.model';
import { MatchPlayerDetail } from '@/models/matchPlayerDetail.model';
import { MatchPlayers } from '@/models/matchPlayerSelect.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';

export const matchDetailService = {
    get,
    edit,
    edits,
    selectPlayers,
    linkedPlayers
};

function edit(match: MatchPlayerDetail) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(match)
    };

    return apiCall(`${config.apiUrl}/matchDetail`, requestOptions);
}

function edits(matches: MatchPlayerDetail[]) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(matches)
    };

    return apiCall(`${config.apiUrl}/matchDetail/edits`, requestOptions);
}

function selectPlayers(playerSelect: MatchPlayers) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(playerSelect)
    };

    return apiCall(`${config.apiUrl}/matchDetail/Players`, requestOptions);
}

function linkedPlayers(players: MatchEventResult[]) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(players)
    };

    return apiCall(`${config.apiUrl}/matchDetail/Linked`, requestOptions);
}

function get(id: number) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/matchDetail/${id}`, requestOptions);
}

