import { Position } from '@/helpers/enums';
import { Club } from '@/models/club.model';
import { MatchPlayers } from '@/models/matchPlayerSelect.model';
import { orderClub, Player } from '@/models/player.model';
import { playerService } from '@/services';
import { matchDetailService } from '@/services/matchDetail.service';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
})
export default class MatchPlayerSelect extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) playRoundId!: number;
  @Prop({ required: true }) clubId!: number;
  @Prop({ required: true }) matchId!: number;
  @Prop({ required: true }) selectedPlayers!: number[];

  players: (Player & { selected: boolean })[] = [];
  selected: number[] = [];

  created() {

  }

  @Watch("clubId")
  async getPlayers() {
    this.players = [];
    const players = await playerService.getByClub(this.playRoundId, this.clubId);
    players.forEach((player: Player & { selected: boolean; }) => player.selected = this.selectedPlayers.some(p => p === player.playerId));
    this.players = players.sort((a: Player, b: Player) => { return orderClub(a, b) });
  }


  selectPlayer(player: Player & { selected: boolean }) {
    player.selected = !player.selected;
  }

  closeDialog() {
    this.$emit("close");
  }

  async save() {
    const request: MatchPlayers = {
      matchId: this.matchId,
      clubId: this.clubId,
      playerIds: this.players.filter(p => p.selected).map(p => p.playerId)
    }
    await matchDetailService.selectPlayers(request);
    this.$emit("save");
  }
}