import jsCookie from 'js-cookie';

const cookieName = "beroundsuperelf.favorites";

export const favoriteService = {
  addFavorite,
  deleteFavorite,
  getFavorites
};

function addFavorite(teamId: number) {

  const cookieValue = jsCookie.get(cookieName);

  let favorites: number[] = [];

  if (cookieValue) {
    favorites = JSON.parse(cookieValue);
  }

  if (!favorites.includes(teamId)) {
    favorites.push(teamId);
  }

  jsCookie.set(cookieName, JSON.stringify(favorites), { expires: 365, sameSite: 'lax' })
}

function deleteFavorite(teamId: number) {
  const cookieValue = jsCookie.get(cookieName);

  let favorites: number[] = [];

  if (cookieValue) {
    favorites = JSON.parse(cookieValue);
  }

  favorites = favorites.filter(f => f !== teamId)

  jsCookie.set(cookieName, JSON.stringify(favorites))
}

function getFavorites() {
  const cookieValue = jsCookie.get(cookieName);

  if (cookieValue) {
    return JSON.parse(cookieValue);
  } else {
    return [];
  }

}
