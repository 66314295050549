import { Component, Vue } from "vue-property-decorator";
import { playerScoreService } from "@/services/playerScore.service";
import { PlayerTotalScore } from "@/models/playerTotalScore.model";
import { Position } from "@/helpers/enums";
import store from "@/store";
import { News } from "@/models/news.model";
import { newsService } from "@/services/news.service";
import NewsDialog from "../news-dialog/news-dialog.vue";
import deleteDialog from "../delete-dialog/delete-dialog.vue";

@Component({
  metaInfo: {
    title: "Nieuws",
    meta: [{ vmid: "description", name: "description", content: "Nieuws van de Beround Superelf" }],
  },
  components: {
    newsDialog: NewsDialog,
    deleteDialog,
  },
})
export default class NewsOverview extends Vue {
  loaded = false;
  news: News[] = [];
  showDialog = false;
  editNews: News = {} as News;
  newsId = 0;
  showDeleteDialog = false;

  get headers() {
    return [
      {
        text: "Positie",
        value: "position",
      },
      {
        text: "Text",
        value: "text",
      },
      { text: "Acties", value: "actions", sortable: false },
    ];
  }

  async created() {
    this.news = await newsService.getNews();
    this.loaded = true;
  }

  toggleDialog(show: boolean, news?: News) {
    this.showDialog = show;

    if (show) {
      this.editNews = Object.assign({}, news ?? ({} as News));
    }
  }

  toggleDeleteDialog(show: boolean, id: number) {
    this.showDeleteDialog = show;

    if (show) {
      this.newsId = id;
    }
  }

  async save() {
    this.showDialog = false;
    this.news = await newsService.getNews();
  }

  async deleteNews(id: number) {
    await newsService.deleteNews(id);
    this.showDeleteDialog = false;
    this.news = await newsService.getNews();
  }
}
