import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DeleteDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) text!: string;
  @Prop({ required: true }) newsId!: number;

  closeDialog() {
    this.$emit("close");
  }

  async deleteNews() {
    this.$emit("delete", this.newsId);
  }
}