import { Team } from "../../models/team.model";
import { Component, Vue } from "vue-property-decorator";
import { teamService } from "../../services/team.service";
import { router } from "@/router";
import { superElfStarted, superElfStartedInThreeDays } from "@/helpers/time-helper";
import store from "@/store";

@Component({
  metaInfo: {
    title: "Winnaars",
    meta: [
      { vmid: "description", name: "description", content: "Winnaars van de Beround Superelf" },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class Winners extends Vue {}
