import { Component, Vue } from "vue-property-decorator";
import { MatchDetail } from '@/models/matchDetail.model';
import { matchDetailService } from '@/services/matchDetail.service';
import MatchPlayerSelect from './match-player-select/match-player-select.vue';
import PlayerEvents from './player-events/player-events.vue';
import PlayerDetail from './player-detail/player-detail.vue';
import PlayerLink from './player-link/player-link.vue';
import { orderDetail } from '@/models/player.model';
import { MatchPlayerDetail } from '@/models/matchPlayerDetail.model';
import { scoreService } from '@/services/flashscore/flashscore.service';
import { MatchEventResult } from '@/models/matchEventResult.model';

@Component({
  components: {
    MatchPlayerSelect,
    PlayerDetail,
    PlayerEvents,
    PlayerLink
  },
  metaInfo: {
    title: "Wedstrijd"
  }
})
export default class MatchDetailOverview extends Vue {
  matchDetails: MatchDetail = {} as MatchDetail;

  matchId = 0;
  loaded = false;
  selectPlayerDialog = false;
  playerDetailDialog = false;
  selectedPlayer: MatchPlayerDetail = {} as MatchPlayerDetail;
  selectedClubId = 0;
  playRoundId = 0
  selectedPlayers: number[] = [];
  missingPlayers: MatchEventResult[] = [];
  playerLinkDialog = false;
  loading = false;

  async created() {
    this.matchId = +this.$route.params.id;
    this.matchDetails = await matchDetailService.get(this.matchId);
    this.playRoundId = this.matchDetails.playRoundId;
    this.loaded = true;
  }

  selectPlayers(clubId: number) {
    this.selectedClubId = clubId;
    this.selectPlayerDialog = true;
    this.selectedPlayers = this.matchDetails.playerDetails.filter(p => p.clubId === clubId).map(p => { return p.playerId ?? 0 });
  }

  playerDetail(detail: MatchPlayerDetail) {
    this.selectedPlayer = detail;
    this.playerDetailDialog = true;
  }

  get homeDetails() {
    return this.matchDetails.playerDetails.filter(p => p.clubId === this.matchDetails.homeId).sort(orderDetail)
  }

  get awayDetails() {
    return this.matchDetails.playerDetails.filter(p => p.clubId === this.matchDetails.awayId).sort(orderDetail)
  }

  async save() {
    this.matchDetails = await matchDetailService.get(this.matchId);
    this.selectPlayerDialog = false;
    this.playerDetailDialog = false;
    this.playerLinkDialog = false;
  }

  async readMatch() {
    try {
      this.loading = true;

      this.missingPlayers = await scoreService.readEvent(this.matchId);
      this.selectedClubId = 0;

      if (this.missingPlayers.length > 0) {
        this.playerLinkDialog = true;
      } else {
        this.matchDetails = await matchDetailService.get(this.matchId);
      }
      this.loading = false;
    }
    catch
    {
      this.loading = false;
    }

  }

  get playRoundUrl() {
    return `/admin/${this.playRoundId}/wedstrijden`
  }
}