import { Settings } from '@/models/settings.model';

const state = { settings: {} }

const actions = {
    set({ commit }: { commit: any }, settings: Settings) {
        commit('setSettings', settings);
    },
};

const mutations = {
    setSettings(state: any, settings: Settings) {
        state.settings = settings;
    }
};

export const settings = {
    namespaced: true,
    state,
    actions,
    mutations
};