import { Team } from '../models/team.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';
import { TeamPlayer } from '@/models/teamPlayer.model';
import { TeamPlayers } from '@/models/teamPlayers.model';

export const teamService = {
    create,
    get,
    getAllById,
    addPlayer,
    getTeam,
    deletePlayer,
    setFormation,
    deleteTeam,
    anyTeams,
    setPaid,
    checkTeams,
    subTeam,
    getSubs
};

function create(team: Team) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(team)
    };

    return apiCall(`${config.apiUrl}/teams`, requestOptions);
}

function deleteTeam(id: any) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/${id}`, requestOptions);
}

function addPlayer(team: TeamPlayer) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(team)
    };

    return apiCall(`${config.apiUrl}/teams/addPlayer`, requestOptions);
}

function setFormation(model: { formation: string, teamId: number }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(model)
    };

    return apiCall(`${config.apiUrl}/teams/SetFormation`, requestOptions);
}

function setPaid(model: { paid: boolean, id: number }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(model)
    };

    return apiCall(`${config.apiUrl}/teams/paid`, requestOptions);
}

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams`, requestOptions);
}

function getAllById(id: number) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/all/${id}`, requestOptions);
}

function getTeam(id: number) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/${id}`, requestOptions);
}

function getSubs(admin: boolean) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/subsOverview/${admin}`, requestOptions);
}

function deletePlayer(id: number) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/deletePlayer/${id}`, requestOptions);
}

function anyTeams() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/anyTeams`, requestOptions);
}

function checkTeams() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/teams/checkTeams`, requestOptions);
}

function subTeam(model: TeamPlayers) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(model)
    };

    return apiCall(`${config.apiUrl}/teams/subs`, requestOptions);
}
