import Vue from "vue";

import App from "./App.vue";
import { router } from "./router/index";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, email, max, min, regex, confirmed } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import nl from "vee-validate/dist/locale/nl.json";
import { adminService } from "./services/admin.service";
import { configure } from "vee-validate";
import VueMeta from "vue-meta";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import config from "./config";

Vue.config.productionTip = false;

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

configure({ mode: "lazy" });

localize("nl", nl);
extend("email", email);
extend("required", required);
extend("max", max);
extend("min", min);
extend("regex", regex);
extend("confirmed", confirmed);

if (process.env.NODE_ENV !== "development") {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: config.appInsite,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
