import { News } from '@/models/news.model';
import { newsService } from '@/services/news.service';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class NewsDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) news!: News;

  public clubs!: string[];

  closeDialog() {
    this.$emit("close");
  }

  async save() {
    await newsService.edit(this.news);
    this.$emit("save");
  }
}