import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';
import { Club } from '@/models/club.model';

export const clubService = {
    get,
    edit
};

function edit(clubs: { id: number, flashScoreId: string }[]) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify(clubs)
    };

    return apiCall(`${config.apiUrl}/club`, requestOptions);
}

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/club`, requestOptions);
}
