import { orderClub, Player } from '@/models/player.model';
import { playerService } from '../services';

const state = {
    players: [],
    clubs: []
};

const actions = {
    async getAll({ commit }: { commit: any }, reset = false): Promise<Player[]> {
        if (state.players.length === 0 || reset) {
            var players = await playerService.get();
            players = players.sort((a: Player, b: Player) => { return orderClub(a, b) });

            commit('setPlayers', players);

            let clubsSorted: string[] = [];
            players.forEach((player: Player) => {
                if (!clubsSorted.includes(player.club)) {
                    clubsSorted.push(player.club)
                }
            });

            clubsSorted = clubsSorted.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
            commit('setClubs', clubsSorted);
        }
        return state.players;
    },
};

const mutations = {
    setPlayers(state: any, payload: any) {
        state.players = payload;
    },
    setClubs(state: any, payload: any) {
        state.clubs = payload;
    }
};

export const players = {
    namespaced: true,
    state,
    actions,
    mutations
};
