import { PlayerInTeam, TeamPlayers } from '@/models/teamPlayers.model';

const state = {
    team: {},
    deletedPlayers: []
};

const actions = {
    set({ commit }: { commit: any }, team: TeamPlayers) {
        commit('set', team);
    },
    delete({ commit }: { commit: any }, playerId: number) {
        commit('delete', playerId);
    },
    add({ commit }: { commit: any }, player: PlayerInTeam) {
        commit('add', player);
    },
    setFormation({ commit }: { commit: any }, formation: string) {
        commit('setFormation', formation);
    },
    sub({ commit }: { commit: any }, playerId: number) {
        commit('sub', playerId);
    },
};

const mutations = {
    set(state: any, team: TeamPlayers) {
        state.team = team;
    },
    setFormation(state: any, formation: string) {
        state.team.formation = formation;
    },
    delete(state: any, playerId: number) {
        const index = state.team.players.findIndex((p: PlayerInTeam) => p.playerId === playerId);
        if (index !== -1) {
            if (state.team.players[index].orgclub && !state.deletedPlayers.some((p: { id: number, club: string }) => p.id === playerId)) {
                state.deletedPlayers.push({ id: playerId, club: state.team.players[index].orgclub })
            }
            state.team.players.splice(index, 1);
        }
    },
    add(state: any, player: PlayerInTeam) {
        if (player && !state.team.players.some((p: PlayerInTeam) => p.playerId === player.playerId)) {
            state.team.players.push(player);

            const deletedPlayer = state.deletedPlayers.find((p: { id: number, club: string }) => p.id === player.playerId);
            if (deletedPlayer) {
                const index = state.deletedPlayers.findIndex((p: { id: number, club: string }) => p.id === player.playerId);
                player.orgclub = deletedPlayer.club;
                state.deletedPlayers.splice(index, 1);
            }

        }
    },
    sub(state: any, playerId: number) {
        const player = state.team.players.find((p: PlayerInTeam) => p.playerId === playerId && p.bank === false);
        if (player) {
            const playerToBase = state.team.players.find((p: PlayerInTeam) => p.position === player.position && p.bank);
            player.bank = true;
            if (playerToBase) {
                playerToBase.bank = false;
            }
        }
    }
};

export const team = {
    namespaced: true,
    state,
    actions,
    mutations
};
