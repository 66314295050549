import { Settings } from '@/models/settings.model';
import { mapState } from 'vuex';
import { Component, Vue } from "vue-property-decorator";
import { priceInMil } from '@/helpers/price-helper';
import { Dashboard } from '@/models/dashboard.model';
import { dashboardService } from '@/services/dashboard.service';
import { teamService } from '@/services';
import store from '@/store';

@Component({
  metaInfo: {
    title: "Dashboard"
  }
})
export default class DashboardPage extends Vue {
  dashboard: Dashboard = {} as Dashboard;
  loaded = false;
  search = "";

  headers = [
    {
      text: 'Positie',
      value: 'position',
    },
    {
      text: 'Speler',
      value: 'name',
    },
    {
      text: 'Club',
      value: 'club',
    },
    { text: 'Gekozen', value: 'chosen' },
    { text: 'Start', value: 'start' },
    { text: 'Bank', value: 'bank' }
  ];
  footer = {
    itemsPerPageOptions: [
      10,
      100,
      - 1
    ]
  }


  async created() {
    this.dashboard = await dashboardService.get();
    this.loaded = true;
  }

  async performCheck() {
    const teams: string[] = await teamService.checkTeams()

    if (teams && teams.length > 0) {
      store.dispatch("alert/error", `Fout in teams: ${teams.toString()}`);
    } else {
      store.dispatch("alert/success", "Alle teams correct.");

    }
  }

}