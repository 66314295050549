import { Component, Vue } from "vue-property-decorator";
import { priceMoneyService } from "@/services/priceMoney.service";
import { Pricemoney } from "@/models/pricemoney.model";

@Component({
  metaInfo: {
    title: "Prijswinnaars",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Prijswinnaars van de Beround Superelf",
      },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class PriceMoneyOverview extends Vue {
  loaded = false;
  prices: Pricemoney[] = [];
  search = "";
  expanded = [];

  headers = [
    {
      text: "Team",
      value: "teamName",
    },
    {
      text: "Totaal",
      value: "amountTotal",
    },
    { text: "", value: "data-table-expand" },
  ];

  async created() {
    this.prices = await priceMoneyService.getPricemoney();
    this.loaded = true;
  }

  displayMoney(money: number) {
    return `${money.toFixed(2).replace(".", ",")}`;
  }
}
