import { Club } from '@/models/club.model';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
})
export default class ClubDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) unknownClubs!: { id: number, name: string, flashScoreId: string }[];
  @Prop({ required: true }) clubs!: Club[];


  closeDialog() {
    this.$emit("close");
  }

  async save() {
    // await playerService.edit(this.player);
    this.$emit("save");
  }
}