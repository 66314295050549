import { Position } from "@/helpers/enums";
import { Component, Vue } from "vue-property-decorator";
import lineUp from "../line-up/line-up.vue";
import searchPlayers from "../../players/search/search-players.vue";
import { router } from "@/router";
import { TeamPlayers } from "@/models/teamPlayers.model";
import { teamService } from "@/services";
import { mapState } from "vuex";
import { Player } from "@/models/player.model";
import store from "@/store";
import confirmSubDialog from "./confirm-sub-dialog.vue";
import { playRoundService } from "@/services/playRound.service";

@Component({
  components: {
    lineUp,
    searchPlayers,
    confirmSubDialog,
  },
  metaInfo: {
    title: "Wijzig team",
  },
  computed: {
    ...mapState("players", ["players"]),
    ...mapState("team", ["team"]),
    ...mapState("account", ["user"]),
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    //@ts-ignore
    if (!this.unSavedChanges) {
      next();
    } else {
      const answer = window.confirm(
        "Je team is niet opgeslagen, als je de pagina verlaat zullen de wissels ongedaan worden gemaakt."
      );
      if (answer) {
        //@ts-ignore
        this.resetTeam();
        next();
      } else {
        next(false);
      }
    }
  },
})
export default class SubTeam extends Vue {
  loaded = false;
  addMode = false;
  addPosition = Position.Keeper;
  addBank = false;
  orgTeam: TeamPlayers = {} as TeamPlayers;
  snackbar = false;
  isBusy = false;

  public players!: Player[];
  public team!: TeamPlayers;
  public user!: any;
  public showConfirm = false;

  async created() {
    this.orgTeam = await teamService.getTeam(+this.$route.params.id);
    this.isBusy = await playRoundService.isBusy();

    if (!this.team || this.team.id !== this.orgTeam.id) {
      this.resetTeam();
    }

    this.loaded = true;

    if (this.teamComplete && this.numberMutations > 0) {
      this.snackbar = true;
    }

    window.addEventListener("beforeunload", this.checkClosePage);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.checkClosePage);
  }

  checkClosePage(evt: BeforeUnloadEvent) {
    if (this.unSavedChanges) {
      evt.preventDefault();
      evt.returnValue = true;
    }
  }

  resetTeam() {
    store.dispatch("team/set", JSON.parse(JSON.stringify(this.orgTeam)));
  }

  search(event: { bank: boolean; position: Position }) {
    router.push(`/teams/wissel/${this.$route.params.id}/players/${event.bank}/${event.position}`);
  }

  deletePlayer(playerId: number) {
    store.dispatch("team/delete", playerId);
  }

  async save() {
    this.showConfirm = false;
    this.orgTeam = await teamService.getTeam(+this.$route.params.id);
    this.resetTeam();
  }

  subPlayer(playerId: number) {
    store.dispatch("team/sub", playerId);
    if (this.unSavedChanges) {
      this.snackbar = true;
    }
  }

  get unSavedChanges() {
    return this.teamComplete && this.numberMutations > 0;
  }

  get teamUrl() {
    if (this.user.id === this.team.accountId) {
      return "/teams";
    } else {
      return `/admin/teams/${this.team.accountId}`;
    }
  }

  get numberMutations() {
    let keepSub = false;
    let defSub = false;
    let midSub = false;
    let attSub = false;

    let nrSubs = 0;
    this.team.players.forEach((p) => {
      const orgPlayer = this.orgTeam.players.find((po) => po.playerId == p.playerId);
      if (!orgPlayer) {
        nrSubs++;
      } else if (orgPlayer.bank !== p.bank) {
        switch (p.position) {
          case Position.Keeper:
            keepSub = true;
            break;
          case Position.Defender:
            defSub = true;
            break;
          case Position.Midfielder:
            midSub = true;
            break;
          case Position.Attacker:
            attSub = true;
            break;
        }
      }
    });

    nrSubs = nrSubs + (15 - this.team.players.length);

    if (keepSub) {
      nrSubs++;
    }

    if (defSub) {
      nrSubs++;
    }

    if (midSub) {
      nrSubs++;
    }

    if (attSub) {
      nrSubs++;
    }

    return nrSubs;
  }

  get totalMutations() {
    return this.orgTeam.subs + this.numberMutations;
  }

  get teamComplete() {
    return this.team.players.length === 15;
  }
}
