import { Position } from "@/helpers/enums";
import { Player } from "@/models/player.model";
import { playerService } from "@/services";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import store from "@/store";

@Component({
  computed: {
    ...mapState("players", ["clubs"]),
  },
})
export default class PlayerDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) player!: Player;

  public clubs!: string[];

  closeDialog() {
    this.$emit("close");
  }

  async save() {
    await playerService.edit(this.player);
    this.$emit("save");
  }

  get canCreateTeams() {
    //@ts-ignore
    return store.state.settings.settings.canCreateTeams;
  }

  async deletePlayer() {
    await playerService.deletePlayer(this.player.playerId);
    this.$emit("save");
  }

  get positionItems() {
    const positions = [
      { text: "Keeper", value: Position.Keeper },
      { text: "Verdediger", value: Position.Defender },
      { text: "Middenvelder", value: Position.Midfielder },
      { text: "Aanvaller", value: Position.Attacker },
    ];
    return positions;
  }
}
