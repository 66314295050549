import { Component, Vue } from "vue-property-decorator";

import { PlayRound } from "@/models/playRound.model";
import { playRoundService } from "@/services/playRound.service";
import { router } from "@/router";
import { convertDateTime } from "@/helpers/time-helper";
import { SubsRounds } from "@/models/subs.model";
import { teamService } from "@/services";

@Component({
  metaInfo: {
    title: "Wissels",
    meta: [
      { vmid: "description", name: "description", content: "Wissels in de Beround Superelf" },
      { name: "robots", content: "nofollow" },
    ],
  },
})
export default class SubOverView extends Vue {
  subs: SubsRounds[] = [];
  loaded = false;
  adminMode = false;

  panel = 0;
  async created() {
    this.loaded = true;
    this.adminMode = this.$route.path.includes("/admin");
    this.subs = await teamService.getSubs(this.adminMode);
  }
}
