import { Settings } from "@/models/settings.model";
import { mapState } from "vuex";
import { Component, Vue } from "vue-property-decorator";
import { priceInMil } from "@/helpers/price-helper";

@Component({
  computed: {
    ...mapState("settings", ["settings"]),
  },
  metaInfo: {
    title: "Spelregels",
    meta: [
      { vmid: "description", name: "description", content: "Spelregels van de Beround Superelf" },
    ],
  },
})
export default class Rules extends Vue {
  public settings!: Settings;

  get budget() {
    return priceInMil(this.settings.budget);
  }

  get startTime() {
    const date = new Date(this.settings.startSuperElf);
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleString("nl-nl", options);
  }

  cupStageName(contenders: number) {
    switch (contenders) {
      case 1:
        return "Winnaar";
      case 2:
        return "Finale";
      case 4:
        return "Halve finale";
      case 8:
        return "Kwart finale";
      default:
        return `Laatste ${contenders}`;
    }
  }
}
