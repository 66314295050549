import { Position } from '@/helpers/enums';
import { MatchEventResult } from '@/models/matchEventResult.model';
import { Player } from '@/models/player.model';
import { playerService } from '@/services';
import { matchDetailService } from '@/services/matchDetail.service';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
})
export default class PlayerLink extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) playersToLink!: MatchEventResult[];
  @Prop({ required: true }) playRoundId!: number;

  players: Player[] = [];
  playersFetched = false;

  async created() {
  }

  closeDialog() {
    this.$emit("close");
  }

  @Watch("playersToLink")
  getPlayers() {
    this.playersToLink.forEach(club =>
      club.players.forEach(player => player.matchDetail.playerId = undefined))
  }

  @Watch("showDialog")
  async toggleDialog() {
    if (!this.playersFetched && this.showDialog) {
      this.players = await playerService.getNotLinked(this.playRoundId);
      this.playersFetched = true;
    }
  }

  async save() {
    await matchDetailService.linkedPlayers(this.playersToLink);
    this.$emit("save");
  }

  items(club: string) {
    var playersClub = this.players.filter((p) => p.club === club);

    playersClub.unshift({ playerId: 0, playerName: "Voeg nieuwe speler toe" } as any);
    playersClub.unshift({ playerId: -1, playerName: "Voeg later toe" } as any);

    return playersClub;
  }
}