import { Component, Vue } from "vue-property-decorator";

import { PlayRound } from "@/models/playRound.model";
import { playRoundService } from "@/services/playRound.service";
import { router } from "@/router";
import { convertDateTime } from "@/helpers/time-helper";

@Component({
  metaInfo: {
    title: "Speelrondes",
  },
})
export default class PlayRoundOverView extends Vue {
  playRounds: PlayRound[] = [];
  showDialog = false;
  deleteDialog = false;
  end = "";
  start = "";
  id = 0;
  period = 0;
  loaded = false;
  dialog = false;
  isCup = false;
  menuStart = false;
  menuEnd = false;
  showTime = false;
  endTime = "";
  startTime = "";

  deleteId = 0;

  async created() {
    this.playRounds = await playRoundService.get();
    this.loaded = true;
  }

  add() {
    this.start = "";
    this.end = "";
    this.id = 0;
    this.showDialog = true;
    this.period = 0;
    this.isCup = false;
  }

  edit(round: PlayRound) {
    this.start = this.ConvertBeDate(round.start);
    this.end = this.ConvertBeDate(round.end);
    this.startTime = this.ConvertBeTime(round.start);
    this.endTime = this.ConvertBeTime(round.end);
    this.id = round.id;
    this.showDialog = true;
    this.period = round.period;
    this.isCup = round.isCup;
  }

  close() {
    this.showDialog = false;
  }

  onSubmit() {
    playRoundService
      .edit({
        start: convertDateTime(this.formattedStartDate),
        end: convertDateTime(this.formattedEndDate),
        id: this.id,
        period: this.period,
        isCup: this.isCup,
      })
      .then(async () => {
        this.playRounds = await playRoundService.get();
        this.showDialog = false;
      });
  }

  showDeleteDialog(id: number) {
    this.deleteId = id;
    this.deleteDialog = true;
  }

  async deleteRound() {
    await playRoundService.deleteRound(this.deleteId);
    this.playRounds = await playRoundService.get();
    this.deleteDialog = false;
  }

  get period1Rounds() {
    return this.periodCountInRounds(1);
  }

  get period2Rounds() {
    return this.periodCountInRounds(2);
  }

  get period3Rounds() {
    return this.periodCountInRounds(3);
  }

  periodCountInRounds(period: number) {
    return this.playRounds.filter((r) => r.period === period && r.isCountInRound).length;
  }

  goToMatch(id: number) {
    router.push(`/admin/${id}/wedstrijden`);
  }

  formatDate(date: string) {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  }

  ConvertBeDate(date: string) {
    if (!date) return "";

    const [day, month, year] = date.split(" ")[0].split("-");
    return `${year}-${month}-${day}`;
  }

  ConvertBeTime(date: string) {
    if (!date) return "";

    return date.split(" ")[1];
  }

  get formattedStartDate() {
    return `${this.formatDate(this.start)} ${this.startTime}`;
  }

  get formattedEndDate() {
    return `${this.formatDate(this.end)} ${this.endTime}`;
  }
}
