import { Position } from "@/helpers/enums";
import { Component, Vue } from "vue-property-decorator";
import { router } from "@/router";
import { TeamPlayers } from "@/models/teamPlayers.model";
import { playerService, teamService } from "@/services";
import { priceInMil } from "@/helpers/price-helper";
import positionIcon from "@/components/players/position-icon/position-icon.vue";
import { PlayerDetail } from "@/models/playerDetail.model";
import clubIcon from "@/components/clubs/club-icon.vue";

@Component({
  components: {
    positionIcon,
    clubIcon,
  },
  metaInfo: {
    title: "Speler info",
    meta: [{ name: "robots", content: "noindex, nofollow" }],
  },
})
export default class PlayerSelectDetail extends Vue {
  player: PlayerDetail = {} as PlayerDetail;
  loaded = false;

  async created() {
    this.player = await playerService.getDetail(+this.$route.params.id);
    this.loaded = true;
  }

  get priceDisplay() {
    return priceInMil(this.player.price);
  }

  get position() {
    switch (this.player.position) {
      case Position.Keeper:
        return "Keeper";
      case Position.Defender:
        return "Verdediger";
      case Position.Midfielder:
        return "Middenvelder";
      case Position.Attacker:
        return "Aanvaller";
    }
  }

  get baseTeams() {
    return this.player.teams.filter((t) => !t.bank).sort((a, b) => a.position - b.position);
  }

  get subTeams() {
    return this.player.teams.filter((t) => t.bank).sort((a, b) => a.position - b.position);
  }

  goBack() {
    this.$router.back();
  }
}
