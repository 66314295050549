import { Position } from '@/helpers/enums';
import { Component, Vue } from "vue-property-decorator";
import lineUp from "../line-up/line-up.vue"
import searchPlayers from "../../players/search/search-players.vue"
import { router } from '@/router';
import { TeamPlayers } from '@/models/teamPlayers.model';
import { teamService } from '@/services';
import { mapState } from 'vuex';

@Component({
  components: {
    lineUp,
    searchPlayers
  },
  metaInfo: {
    title: "Wijzig team"
  },
  computed: {
    ...mapState("account", ["user"])
  },
})
export default class EditTeam extends Vue {

  team: TeamPlayers = {} as TeamPlayers;
  loaded = false;

  public user!: any


  async created() {
    this.team = await teamService.getTeam(+this.$route.params.id);
    this.loaded = true;
  }

  search(event: { bank: boolean, position: Position }) {
    router.push(`/teams/edit/${this.$route.params.id}/players/${event.bank}/${event.position}`)
  }

  refresh(team: TeamPlayers) {
    this.team = team;
  }

  get breadCrumbsItems() {
    return [
      {
        text: 'Mijn teams',
        disabled: false,
        to: this.teamUrl,
        exact: true
      },
      {
        text: this.team.teamName,
        disabled: false,
        to: `/teams/edit/${this.team.id}`,
        exact: true
      }
    ]
  }

  get teamUrl() {
    if (this.user.id === this.team.accountId) {
      return "/teams";
    } else {
      return `/admin/teams/${this.team.accountId}`
    }
  }
}