import { userService } from "../services";
import { router } from "../router/index";

const user = JSON.parse(localStorage.getItem("user") as any);
const state = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

const actions = {
  login(
    { dispatch, commit }: { dispatch: any; commit: any },
    { email, password }: { email: string; password: string }
  ): Promise<any> {
    commit("loginRequest", { email });

    return userService.login(email, password).then(
      (user: any) => {
        commit("loginSuccess", user);
        router.push("/");
      },
      (error: any) => {
        commit("loginFailure", error);
      }
    );
  },
  logout({ commit }: { commit: any }) {
    userService.logout();
    commit("logout");
  },
};

const mutations = {
  loginRequest(state: any, user: any) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state: any, user: any) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state: any) {
    state.status = {};
    state.user = null;
  },
  logout(state: any) {
    state.status = {};
    state.user = null;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
};
