import { Settings } from '@/models/settings.model';
import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';

export const homeService = {
  getSettings,
  getTiles
};

function getSettings() {
  const requestOptions = {
    method: 'GET'
  };

  return apiCall(`${config.apiUrl}/home/settings`, requestOptions);
}

function getTiles(favorites: number[]) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const queryParams = favorites.map(f => "fv=" + f).join("&");
  let query = "";

  if (queryParams) {
    query = `?${queryParams}`;
  }

  return apiCall(`${config.apiUrl}/home/tiles${query}`, requestOptions);
}
