import config from '@/config';
import { router } from '@/router';
import { userService } from '@/services';
import store from '@/store';
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha'

@Component({
  methods: {
    ...mapActions("account", ["register"])
  },
  computed: {
    ...mapState("account", ["status"])
  },
  components: {
    VueRecaptcha
  },
  metaInfo: {
    title: "Registreren",
    meta: [
      { vmid: 'description', name: 'description', content: 'Registreer je voor de Beround Superelf' },
    ]
  }
})
export default class Register extends Vue {
  user = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  submitted = false;
  showPassword = false;
  showConfirm = false;


  register!: (model: any) => any;
  logout!: () => any;

  onSubmit() {
    this.submitted = true;
    //@ts-ignore
    this.$refs.recaptcha.execute();
  }

  onCaptchaVerified() {
    userService.register(this.user)
      .then(
        () => {
          router.push('/login');
          setTimeout(() => {
            // display success message after route change completes
            store.dispatch('alert/success', 'Registreren is geslaagd', { root: true });
          })
        },
      ).finally(() => this.submitted = false);
    //@ts-ignore    
    this.$refs.recaptcha.reset();
  }

  onCaptchaExpired() {
    //@ts-ignore
    this.$refs.recaptcha.reset();
  }

  get recaptchaKey() {
    return config.recaptchaSite;
  }

}