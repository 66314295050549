import { Component, Vue } from "vue-property-decorator";
import { playRoundService } from '@/services/playRound.service';
import { Match } from '@/models/match.model';
import { matchService } from '@/services/match.service';
import { Club } from '@/models/club.model';
import { clubService } from '@/services/club.service';
import { scoreService } from '@/services/flashscore/flashscore.service';
import { PlayRound } from '@/models/playRound.model';
import clubDialog from '../clubs/club-dialog.vue'
import { MatchResult } from '@/models/matchResult.model';
import { MatchEventResult } from '@/models/matchEventResult.model';
import playerLink from '../matchDetail/player-link/player-link.vue';
import { playerScoreService } from '@/services/playerScore.service';
import store from '@/store';

@Component({
  components: {
    clubDialog,
    playerLink
  },
  metaInfo: {
    title: "Wedstrijden"
  }
})
export default class MatchOverview extends Vue {
  matches: Match[] = [];
  showDialog = false;
  deleteDialog = false;
  clubDialog = false;
  calculateDialog = false;
  homeId: number | null = null;
  awayId: number | null = null;
  homeScore = 0;
  awayScore = 0;
  id = 0;
  loaded = false;
  dialog = false;
  clubs: Club[] = [];

  deleteId = 0;
  playRoundId = 0;
  playRound: PlayRound = {} as PlayRound;

  matchScores: MatchResult[] = [];
  importMatches: Match[] = [];
  unknownClubs: { id?: number, name: string, flashScoreId: string }[] = [];
  knownClubs: { id: number, flashScoreId: string }[] = [];
  missingPlayers: MatchEventResult[] = [];
  playerLinkDialog = false;
  loading = false;
  calculating = false;

  async created() {
    this.playRoundId = +this.$route.params.id;
    this.playRound = await playRoundService.getById(this.playRoundId);
    this.matches = await matchService.get(this.playRoundId);
    this.clubs = await clubService.get();
    this.loaded = true;
  }

  add() {
    this.homeId = null;
    this.awayId = null;
    this.homeScore = 0;
    this.awayScore = 0;
    this.id = 0;
    this.showDialog = true;
  }

  edit(match: Match) {
    this.homeId = match.homeId;
    this.awayId = match.awayId;
    this.homeScore = match.homeScore
    this.awayScore = match.awayScore;
    this.id = match.id;
    this.showDialog = true;
  }

  close() {
    this.showDialog = false;
  }

  onSubmit() {
    matchService.edit({
      playRoundId: this.playRoundId, homeId: this.homeId!,
      awayId: this.awayId!, homeScore: this.homeScore, awayScore: this.awayScore, id: this.id
    }).then(async () => {
      this.matches = await matchService.get(this.playRoundId);
      this.showDialog = false
    })
  }

  showDeleteDialog(id: number) {
    this.deleteId = id;
    this.deleteDialog = true;
  }

  async deleteRound() {
    await matchService.deleteMatch(this.deleteId);
    this.matches = await matchService.get(this.playRoundId);
    this.deleteDialog = false;
  }

  async readMatches() {
    try {
      this.loading = true;
      this.matchScores = await scoreService.readResults(this.playRound.id);
      this.importMatches = [];
      this.unknownClubs = [];
      this.knownClubs = [];

      this.matchScores.forEach(matchScore => {
        if (!this.clubs.some(c => c.flashScoreId === matchScore.homeId)) {
          const matchedClub = this.clubs.find(c => c.name == matchScore.homeName);
          if (matchedClub) {
            this.knownClubs.push({ id: matchedClub.id, flashScoreId: matchScore.homeId })
          } else {
            this.unknownClubs.push({ flashScoreId: matchScore.homeId, name: matchScore.homeName })
          }
        }

        if (!this.clubs.some(c => c.flashScoreId === matchScore.awayId)) {
          const matchedClub = this.clubs.find(c => c.name == matchScore.awayName);
          if (matchedClub) {
            this.knownClubs.push({ id: matchedClub.id, flashScoreId: matchScore.awayId })
          } else {
            this.unknownClubs.push({ flashScoreId: matchScore.awayId, name: matchScore.awayName })
          }
        }
      });

      if (this.unknownClubs.length > 0) {
        this.clubDialog = true;
      } else if (this.knownClubs.length > 0) {
        await this.saveKnown();
      } else {
        await this.saveMatches();
      }

      this.loading = false;
    }
    catch
    {
      this.loading = false;
    }
  }

  async saveKnown() {
    await clubService.edit(this.knownClubs.map(u => { return { id: u.id!, flashScoreId: u.flashScoreId } }));
    this.clubs = await clubService.get();
    await this.saveMatches()

  }

  async saveUnknown() {
    this.unknownClubs.forEach(club => {
      this.knownClubs.push({ id: club.id!, flashScoreId: club.flashScoreId });
    })

    this.clubDialog = false;
    this.saveKnown();
  }

  async saveMatches() {
    this.matchScores.forEach(matchScore => {
      const homeClub = this.clubs.find(c => c.flashScoreId === matchScore.homeId);
      const awayClub = this.clubs.find(c => c.flashScoreId === matchScore.awayId);

      if (homeClub && awayClub) {
        const match: Match = {
          homeId: homeClub?.id,
          awayId: awayClub?.id,
          homeScore: matchScore.homeScore,
          awayScore: matchScore.awayScore,
          playRoundId: this.playRoundId,
          id: 0,
          flashScoreId: matchScore.eventId
        }

        this.importMatches.push(match);
      }
    });

    this.missingPlayers = await matchService.addEdit(this.importMatches);

    if (this.missingPlayers.length > 0) {
      this.playerLinkDialog = true;
    } else {
      this.matches = await matchService.get(this.playRoundId);
    }
  }

  async savePlayers() {
    this.matches = await matchService.get(this.playRoundId);
    this.playerLinkDialog = false;
  }

  async calculate() {
    this.calculating = true;
    await playerScoreService.calculate(this.playRoundId);
    store.dispatch('alert/success', 'Punten zijn berekend');
    this.calculating = false;
    this.calculateDialog = false;
  }

  get playRoundUrl() {
    return `/admin/speelrondes`;
  }

}