import { Position } from '@/helpers/enums';
import { priceInMil } from '@/helpers/price-helper';
import { superElfStarted } from '@/helpers/time-helper';
import { TeamPlayers } from '@/models/teamPlayers.model';
import { teamService } from '@/services';
import { Component, Prop, Vue } from "vue-property-decorator";
import positionIcon from "../../../players/position-icon/position-icon.vue"
import clubIcon from '@/components/clubs/club-icon.vue';

@Component({
  components: { positionIcon, clubIcon }
})
export default class LineUpPlayer extends Vue {
  @Prop({ required: true }) position!: Position;
  @Prop({ required: true }) team!: TeamPlayers;
  @Prop({ required: true }) index!: number;
  @Prop({ required: false, default: false }) bank!: boolean;
  @Prop({ required: true }) subMode!: boolean;
  @Prop({ required: false, default: false }) canSub!: boolean;

  deleteBusy = false;

  get selectText() {
    switch (this.position) {
      case Position.Keeper:
        return "Selecteer keeper"
      case Position.Defender:
        return "Selecteer verdediger"
      case Position.Midfielder:
        return "Selecteer middenvelder"
      case Position.Attacker:
        return "Selecteer aanvaller"
    }
  }

  add() {
    this.$emit("add");
  }

  async deletePlayer() {
    if (this.player) {
      if (!this.subMode) {
        this.deleteBusy = true;
        try {
          var team = await teamService.deletePlayer(this.player.id);
          this.$emit("refresh", team);
        }
        catch {
          var team = await teamService.getTeam(this.team.id);
          this.$emit("refresh", team);
        }
        finally {
          this.deleteBusy = false
        }
      } else {
        this.$emit("deletePlayer", this.player.playerId);
      }
    }
  }

  subPlayer() {
    if (this.player) {
      this.$emit("subPlayer", this.player.playerId);
    }
  }

  get player() {
    const playersPosition = this.team.players.filter(p => p.position == this.position && p.bank == this.bank)
    if (playersPosition.length >= this.index + 1) {
      return playersPosition[this.index];
    }

    return null;
  }

  get PriceInMil() {
    if (this.player) {
      const price = priceInMil(this.player.price);

      return `€${price}`;
    }

    return 0;
  }

  get editPossible() {
    return !superElfStarted();
  }
}