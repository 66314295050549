const state = {
    loading: false
};

const actions = {
    start({ commit }: { commit: any }) {
        commit('startRequest');
    },
    end({ commit }: { commit: any }) {
        commit('endRequest');
    }

};

const mutations = {
    startRequest(state: any) {
        state.loading = true;
    },
    endRequest(state: any) {
        state.loading = false;
    }
};

export const backend = {
    namespaced: true,
    state,
    actions,
    mutations
};