import { router } from "@/router";
import { userService } from "@/services";
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";

@Component({
  methods: {
    ...mapActions("account", ["register"]),
  },
  computed: {
    ...mapState("account", ["status"]),
  },
  metaInfo: {
    title: "Wijzig wachtwoord",
    meta: [{ vmid: "description", name: "description", content: "Wijzig wachtwoord" }],
  },
})
export default class PasswordChange extends Vue {
  password = "";
  confirmPassword = "";
  submitted = false;
  showPassword = false;
  showConfirm = false;

  register!: (model: any) => any;
  logout!: () => any;
  token = "";
  valid = false;

  async created() {
    this.token = this.$route.query.token as string;
    this.valid = await userService.validateResetToken({ token: this.token });
  }

  onSubmit() {
    this.submitted = true;
    userService
      .resetPassword({
        token: this.token,
        password: this.password,
        confirmPassword: this.confirmPassword,
      })
      .then(() => {
        router.push("/login");
        setTimeout(() => {
          store.dispatch("alert/success", "Wachtwoord reset is gelukt", { root: true });
        });
      })
      .finally(() => (this.submitted = false));
  }
}
