import { Component, Vue } from "vue-property-decorator";
import { playerScoreService } from "@/services/playerScore.service";
import { PlayerTotalScore } from "@/models/playerTotalScore.model";
import { Position } from "@/helpers/enums";
import clubIcon from "../../clubs/club-icon.vue";
import store from "@/store";

@Component({
  metaInfo: {
    title: "Speler stats",
    meta: [
      { vmid: "description", name: "description", content: "Statistieken van de Beround Superelf" },
      { name: "robots", content: "nofollow" },
    ],
  },
  components: {
    clubIcon,
  },
})
export default class PlayerTotalStats extends Vue {
  loaded = false;
  scores: PlayerTotalScore[] = [];
  search = "";
  allPositions: string = "Alle posities";
  filterPosition: string | null = null;
  filterClub: string | null = null;
  filterPrice: number | null = null;

  get headers() {
    return [
      {
        text: "Positie",
        value: "position",
        width: "100px",
        filter: (value: string) => {
          if (!this.filterPosition) return true;

          return value === this.filterPosition;
        },
      },
      {
        text: "Speler",
        value: "playerName",
      },
      {
        text: "Club",
        value: "club",
        filter: (value: string) => {
          if (!this.filterClub) return true;

          return value === this.filterClub;
        },
      },
      {
        text: "Prijs",
        value: "price",
        filter: (value: number) => {
          if (!this.filterPrice) return true;

          return value <= this.filterPrice;
        },
      },
      { text: "score", value: "score" },
      { text: "basis", value: "startTeam" },
      { text: "wissel", value: "subTeam" },
    ];
  }

  footer = {
    itemsPerPageOptions: [10, 100, -1],
  };

  async created() {
    await store.dispatch("players/getAll");

    this.scores = await playerScoreService.getScores();
    this.loaded = true;
  }

  get positionFilter() {
    const positions = [
      { text: this.allPositions, value: null },
      { text: "Keepers", value: Position.Keeper },
      { text: "Verdedigers", value: Position.Defender },
      { text: "Middenvelders", value: Position.Midfielder },
      { text: "Aanvallers", value: Position.Attacker },
    ];
    return positions;
  }

  get clubs() {
    let distinctClubs: { text: string; value: string | null }[] = [];
    distinctClubs.push({ text: "Alle clubs", value: null });

    //@ts-ignore

    store.state.players.clubs.forEach((club) => {
      distinctClubs.push({ text: club, value: club });
    });

    return distinctClubs;
  }

  rowClick(item: PlayerTotalScore) {
    this.$router.push(`/speler/${item.playerId}`);
  }
}
