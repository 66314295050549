var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded && _vm.prices.length > 0)?_c('div',{staticClass:"pricemoney-overview"},[_c('h2',{staticClass:"text-center"},[_vm._v("Prijzengeld")]),_c('div',{staticClass:"medium-table"},[_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.prices,"search":_vm.search,"mobile-breakpoint":"200","header-props":{ sortIcon: null },"disable-pagination":true,"hide-default-footer":true,"dense":true,"sort-by":"amountTotal","sort-desc":true,"single-expand":true,"expanded":_vm.expanded,"item-key":"teamName","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.teamName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/stand/team/" + (item.teamId))}},[_vm._v(_vm._s(item.teamName))])]}},{key:"item.amountTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayMoney(item.amountTotal)))]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pt-2 pb-2"},[(item.amountCup)?_c('tr',[_c('td',[_vm._v(_vm._s(("Beker: " + (_vm.displayMoney(item.amountCup)))))])]):_vm._e(),(item.amountRound)?_c('tr',[_c('td',[_vm._v(_vm._s(("Ronde: " + (_vm.displayMoney(item.amountRound)))))])]):_vm._e(),(item.amountPeriod)?_c('tr',[_c('td',[_vm._v(_vm._s(("Periode: " + (_vm.displayMoney(item.amountPeriod)))))])]):_vm._e(),(item.amountFinal)?_c('tr',[_c('td',[_vm._v(_vm._s(("Eindstand: " + (_vm.displayMoney(item.amountFinal)))))])]):_vm._e()]),_c('td')]}}],null,false,2098521117)})],1)]):(_vm.loaded)?_c('div',[_c('p',[_vm._v("Prijzengeld is nog niet beschikbaar")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }