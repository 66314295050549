import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";

@Component({
  methods: {
    ...mapActions("account", ["login", "logout"]),
  },
  computed: {
    ...mapState("backend", ["loading"]),
  },
  components: {
    VueRecaptcha,
  },
  metaInfo: {
    title: "Inloggen",
    meta: [
      { vmid: "description", name: "description", content: "Inloggen bij de Beround Superelf" },
    ],
  },
})
export default class Login extends Vue {
  email = "";
  password = "";
  showPassword = false;
  submitted = false;
  loading!: boolean;

  login!: (model: { email: string; password: string }) => any;
  logout!: () => any;

  created() {
    this.logout();
  }

  async onSubmit() {
    this.submitted = true;
    await this.login({ email: this.email, password: this.password });
    this.submitted = false;
  }
}
