import { PriceSettingsModel } from '@/models/settings.model';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Settings"
  }
})
export default class SettingsPage extends Vue {

  @Prop({ required: true }) items!: PriceSettingsModel[];

  dialog = false;
  dialogDelete = false;
  headers = [
    { text: 'Positie', value: 'position' },
    { text: 'Prijs', value: 'price' },
    { text: 'Acties', value: 'actions', sortable: false }
  ];

  editedIndex = -1;
  editedItem = {
    position: 0,
    price: 0,
  };
  defaultItem = {
    position: 0,
    price: 0,
  };


  editItem(item: PriceSettingsModel) {
    this.editedIndex = this.items.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialog = true
  };

  deleteItem(item: PriceSettingsModel) {
    this.editedIndex = this.items.indexOf(item)
    this.items.splice(this.editedIndex, 1)
  }

  close() {
    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    })
  }

  save() {
    if (this.editedIndex > -1) {
      Object.assign(this.items[this.editedIndex], this.editedItem)
    } else {
      this.items.push(this.editedItem)
    }
    this.close()
  }

  get formTitle() {
    return this.editedIndex === -1 ? 'Toevoegen' : 'Wijzigen'
  }
}  