var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('h1',[_vm._v("Registreren")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.recaptchaKey,"size":"invisible"},on:{"verify":_vm.onCaptchaVerified,"expired":_vm.onCaptchaExpired}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"Naam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Naam","hint":"Vul hier je eigen naam, bij je team kan je een alternatieve naam opgeven","persistent-hint":true},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"autocomplete":"username","label":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Wachtwoord","rules":"required|min:6","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"autocomplete":"new-password","label":"Wachtwoord"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Herhaal wachtwoord","rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showConfirm ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirm ? 'text' : 'password',"label":"Herhaal wachtwoord"},on:{"click:append":function($event){_vm.showConfirm = !_vm.showConfirm}},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})]}}],null,true)}),_c('v-btn',{attrs:{"type":"submit","color":"green","loading":_vm.submitted}},[_vm._v("Opslaan ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"type":"button","to":"/login"}},[_vm._v("Annuleren")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }