import { Component, Vue } from "vue-property-decorator";
import messageDialog from '../message-dialog/message-dialog.vue';
import { Message } from '@/models/message.model';
import { messageService } from '@/services/message.service';
import { mapState } from 'vuex';
import { News } from '@/models/news.model';

@Component({
  metaInfo: {
    title: "Berichten"
  },
  components: {
    messageDialog,
  },
  computed: {
    ...mapState("account", ["user"])
  }
})
export default class MessageOverview extends Vue {
  loaded = false;
  messages: Message[] = []
  news: News[] = [];
  showDialog = false;
  editMessage: Message = {} as Message;
  messageId = 0;
  snackbar = false;
  newMessage: Message = { id: 0, forAdmin: true, read: false, text: "" };


  public user!: any


  get isAdmin() {
    return this.user?.role === "Admin"
  }

  get headers() {
    var headers = [
      {
        text: 'Bericht',
        value: 'text',
      },
      {
        text: 'Soort',
        value: 'send'
      },
    ];

    if (this.isAdmin) {
      headers.push({ text: 'Van', value: 'name' });
      // headers.push({ text: 'Acties', value: 'actions' })
    }


    return headers;
  }

  async created() {
    this.messages = await this.getMessages();
    this.loaded = true;
  }

  status(message: Message) {
    let send = true;

    if (this.isAdmin) {
      if (message.accountId !== this.user.id && message.forAdmin) {
        send = false;
      }
    } else {
      send = message.forAdmin;
    }

    return send ? 'Verzonden' : 'Ontvangen'

  }

  toggleDialog(show: boolean, message?: Message) {
    this.showDialog = show;

    if (show) {
      this.editMessage = Object.assign({}, message ?? {} as Message);
    }
  }

  async getMessages() {
    return this.isAdmin ? await messageService.getAdminMessages() : await messageService.getMessages()
  }

  async afterSave() {
    this.showDialog = false;
    this.messages = await this.getMessages();
  }

  async save() {
    await messageService.edit(this.newMessage);
    this.snackbar = true;
    //@ts-ignore
    this.$refs.observer.reset();
    this.newMessage = { id: 0, forAdmin: true, read: false, text: "" };
  }
}