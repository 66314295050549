import { mapState } from "vuex";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Account } from "@/models/account.model";
import { TileStanding } from "@/models/homeTiles.model";
import clubIcon from "../clubs/club-icon.vue";

@Component({
  computed: {
    ...mapState("account", ["user"]),
  },
  metaInfo: {
    title: "Home",
  },
  components: {
    clubIcon,
  },
})
export default class CupTile extends Vue {
  @Prop({ required: true }) standing!: TileStanding[];
  @Prop({ required: true }) isFavorite!: boolean;

  public user!: Account;
}
