<template>
  <v-app>
    <nav-menu :drawer="drawer" v-if="loaded" />

    <v-app-bar app color="#f77027" dark>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      <div class="align-center text-body-1 text-sm-h4">Beround Superelf</div>

      <v-progress-circular
        v-if="showLoader"
        indeterminate
        color="black"
      ></v-progress-circular>

      <v-spacer></v-spacer>

      <template v-if="loaded">
        <v-btn v-if="!account.status.loggedIn" to="/login" color="green">
          <span>Inloggen</span>
        </v-btn>
        <v-btn
          v-if="!account.status.loggedIn && !isStarted"
          to="/register"
          color="orange"
          class="d-none d-sm-flex ml-2"
        >
          <span>Registreren</span>
        </v-btn>
        <v-btn
          v-if="account.status.loggedIn"
          @click="logoutAndHome"
          color="green"
        >
          <span>Uitloggen</span>
        </v-btn>
      </template>
    </v-app-bar>
    <v-main class="pb-0">
      <v-container fluid v-if="loaded">
        <v-alert v-if="alert.message" :type="alert.type">
          {{ alert.message }}
        </v-alert>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
      <v-overlay :opacity="1" :value="showSpinner">
        <div class="d-flex flex-column align-center">
          <v-progress-circular indeterminate size="64"> </v-progress-circular>
          <div>Bezig met laden...</div>
        </div>
      </v-overlay>
    </v-main>
  </v-app>
</template>
<style lang="scss" src="./style/common.scss"></style>;
<script>
import { mapState, mapActions } from "vuex";
import navMenu from "./components/nav-menu/nav-menu.vue";
import { router } from "@/router";
import { homeService } from "@/services";
import store from "./store";
import { superElfStarted } from "@/helpers/time-helper";

export default {
  name: "app",
  metaInfo: {
    title: "Home",
    titleTemplate: "%s | Beround Superelf",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Speel mee met de Beround Superelf. Stel je eredivisie team samen en maak het voetbal kijken leuker en spannender.",
      },
    ],
  },
  components: { navMenu },
  data() {
    return {
      drawer: null,
      showLoader: false,
      loaderTimer: 0,
      loaded: false,
      showSpinner: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      account: (state) => state.account,
      backend: (state) => state.backend,
    }),
    triggerLoaderTimer() {
      if (this.backend.loading) {
        this.loaderTimer = setTimeout(() => {
          this.showLoader = true;
        }, 300);
      } else {
        clearTimeout(this.loaderTimer);
        this.showLoader = false;
      }

      return;
    },
    isStarted() {
      return superElfStarted();
    },
  },
  async created() {
    const spinnerTimer = setTimeout(() => (this.showSpinner = true), 400);
    await homeService.getSettings().then((response) => {
      store.dispatch("settings/set", response);
    });
    clearTimeout(spinnerTimer);

    if (this.account.user) {
      const tokenSplit = this.account.user.jwtToken.split(".");

      var expired = new Date(JSON.parse(atob(tokenSplit[1])).exp * 1000);

      const now = new Date();
      const now60 = new Date(now.getTime() - 60 * 60000);

      if (expired < now60) {
        store.dispatch("account/logout");
      }
    }

    this.showSpinner = false;
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      logout: "account/logout",
    }),
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    logoutAndHome() {
      this.logout();
      router.push("/").catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
    },
  },
};
</script>
