import { Message } from '@/models/message.model';
import { messageService } from '@/services/message.service';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class MessageDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) message!: Message;

  newMessage: Message = { id: 0, forAdmin: true, read: false, text: "" };

  closeDialog() {
    this.$emit("close");
  }

  async save() {
    if (this.message.id) {
      this.newMessage.replyId = this.message.id;
    }

    await messageService.edit(this.newMessage);
    this.$emit("save");
    this.newMessage = { id: 0, forAdmin: true, read: false, text: "" };
  }
}