import config from '@/config';
import { apiCall } from '@/helpers/service-helper';
import { MatchResult } from '@/models/matchResult.model';
import results from './results.json';
//Stage_id SfQjVhXC

export const scoreService = {
  readResults,
  readEvent
};

function readResults(id: number) {
  const requestOptions = {
    method: 'GET'
  };

  return apiCall(`${config.apiUrl}/flashscore/${id}`, requestOptions);

}

function readEvent(id: number) {
  const requestOptions = {
    method: 'GET'
  };

  return apiCall(`${config.apiUrl}/flashscore/match/${id}`, requestOptions);

}