import { Position } from '@/helpers/enums';
import { Player } from '@/models/player.model';
import { TeamPlayer } from '@/models/teamPlayer.model';
import { TeamPlayers } from '@/models/teamPlayers.model';
import { playerService, teamService } from '@/services';
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from 'vuex';


@Component({
  computed: {
    ...mapState("team", ["team"])
  }
})
export default class ConfirmSubDialog extends Vue {
  @Prop({ required: true, default: false }) showDialog!: boolean;
  @Prop({ required: true }) orgTeam!: TeamPlayers;
  @Prop({ required: true }) nrSubs!: number;

  public team!: TeamPlayers;
  busySaving = false;

  closeDialog() {
    this.$emit("close");
  }

  async save() {
    this.busySaving = true;
    await teamService.subTeam(this.team)
      .then(() => {
        this.$emit("save");

      })
      .catch(() => {
        this.$emit("close");
      });

    this.busySaving = false;
  }

  get summary() {
    const sumRows: { name: string, order: number }[] = [];
    this.team.players.forEach(p => {
      const orgPlayer = this.orgTeam.players.find(po => po.playerId == p.playerId);
      if (!orgPlayer) {
        sumRows.push({ name: `Nieuwe speler: ${p.playerName}`, order: 1 });
      } else if (orgPlayer.bank !== p.bank) {
        sumRows.push({ name: `Naar ${p.bank ? "bank" : "basis"}: ${p.playerName}`, order: p.bank ? 4 : 2 });
      }
    });

    this.orgTeam.players.forEach(p => {
      const newPlayer = this.team.players.find(po => po.playerId == p.playerId);
      if (!newPlayer) {
        sumRows.push({ name: `Verwijderde speler: ${p.playerName}`, order: 3 });
      }
    });

    return sumRows.sort((a, b) => a.order - b.order);
  }
}