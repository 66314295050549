import { Position } from '@/helpers/enums';
import { budgetLeft, priceInMil } from '@/helpers/price-helper';
import { superElfStarted } from '@/helpers/time-helper';
import { TeamPlayers } from '@/models/teamPlayers.model';
import { teamService } from '@/services/team.service';
import store from '@/store';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import lineUpPlayer from './line-up-player/line-up-player.vue';

@Component({
  components: { lineUpPlayer },
  metaInfo() {
    return {
      //@ts-ignore
      title: `${this.team.teamName} | Opstelling`
    }
  }
})
export default class LineUp extends Vue {
  @Prop({ required: true }) team!: TeamPlayers;
  @Prop({ required: true }) subMode!: boolean;
  @Prop({ required: false, default: false }) canSub!: boolean;

  position = Position;
  loaded = false;

  async created() {
    this.loaded = true;
  }

  changeFormation() {

    if (this.subMode) {
      store.dispatch("team/setFormation", this.team.formation);
    } else {
      teamService.setFormation({ formation: this.team.formation, teamId: this.teamId });
    }

  }


  get possible442() {
    return this.nrOnPosition(Position.Defender) <= 4 &&
      this.nrOnPosition(Position.Midfielder) <= 4 &&
      this.nrOnPosition(Position.Attacker) <= 2;
  }

  get possible433() {
    return this.nrOnPosition(Position.Defender) <= 4 &&
      this.nrOnPosition(Position.Midfielder) <= 3 &&
      this.nrOnPosition(Position.Attacker) <= 3;
  }

  get possible343() {
    return this.nrOnPosition(Position.Defender) <= 3 &&
      this.nrOnPosition(Position.Midfielder) <= 4 &&
      this.nrOnPosition(Position.Attacker) <= 3;
  }

  private nrOnPosition(position: Position) {
    return this.team.players.filter(p => !p.bank && p.position === position).length;
  }

  get defenders() {
    return this.getPosition(0);
  }

  get midfielders() {
    return this.getPosition(1);
  }

  get attackers() {
    return this.getPosition(2);
  }

  get budgetLeft() {
    return this.team ? `€ ${priceInMil(budgetLeft(this.team))}` : 0;
  }

  get teamId() {
    return +this.$route.params.id;
  }

  get editNotPossible() {
    return superElfStarted();
  }

  getPosition(lineUpNumber: number) {
    const numberPos = +this.team.formation.split("-")[lineUpNumber];

    const positions = [];
    for (let i = 0; i < numberPos; i++) {
      positions.push({});
    }

    return positions;
  }

  addPlayer(bank: boolean, position: Position) {
    this.$emit("searchPlayer", { bank, position })
  }

  refresh(team: TeamPlayers) {
    this.$emit("refresh", team)
  }

  deletePlayer(playerId: number) {
    this.$emit("deletePlayer", playerId)
  }

  subPlayer(playerId: number) {
    this.$emit("subPlayer", playerId)
  }

}