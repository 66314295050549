import config from "../config";
import { apiCall } from "../helpers/service-helper";
import { authHeader } from "@/helpers/auth-header";

export const priceMoneyService = {
  getRoundWinner,
  getPricemoney,
  calculateCup,
  calculatePeriod,
  calculateTotal,
  getAccountmoney,
};

function getRoundWinner() {
  const requestOptions = {
    method: "GET",
  };

  return apiCall(`${config.apiUrl}/priceMoney/round`, requestOptions);
}

function getPricemoney() {
  const requestOptions = {
    method: "GET",
  };

  return apiCall(`${config.apiUrl}/priceMoney`, requestOptions);
}

function getAccountmoney() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return apiCall(`${config.apiUrl}/priceMoney/total`, requestOptions);
}

function calculateCup() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), ...{ "Content-Type": "application/json" } },
    body: null,
  };

  return apiCall(`${config.apiUrl}/priceMoney/cup`, requestOptions);
}

function calculatePeriod() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), ...{ "Content-Type": "application/json" } },
    body: null,
  };

  return apiCall(`${config.apiUrl}/priceMoney/period`, requestOptions);
}

function calculateTotal() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), ...{ "Content-Type": "application/json" } },
    body: null,
  };

  return apiCall(`${config.apiUrl}/priceMoney/total`, requestOptions);
}
