var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAdmin && _vm.loaded)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.messages,"disable-sort":true,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.send",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.status(item)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.toggleDialog(true,item)}}},[_vm._v(" mdi-reply ")])]}}],null,false,3387842115)}),_c('message-dialog',{attrs:{"message":_vm.editMessage,"show-dialog":_vm.showDialog},on:{"close":function($event){return _vm.toggleDialog(false, undefined)},"save":_vm.afterSave}})],1):(!_vm.isAdmin)?_c('div',{staticClass:"default-width"},[_c('h1',[_vm._v("Contact")]),_c('p',[_vm._v("Verstuur hier onder je vraag of opmerking.")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('validation-provider',{attrs:{"name":"Bericht","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Bericht","filled":""},model:{value:(_vm.newMessage.text),callback:function ($$v) {_vm.$set(_vm.newMessage, "text", $$v)},expression:"newMessage.text"}})]}}],null,true)}),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Verzenden")])],1)]}}])}),_c('v-snackbar',{staticClass:"mt-16",attrs:{"color":"green","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(("Je bericht is verzonden. Je ontvangt reactie op je e-mailadres " + (_vm.user.email) + "."))+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }