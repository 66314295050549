import config from '../config';
import { authHeader } from '../helpers/auth-header';
import { apiCall } from '../helpers/service-helper';

export const cupService = {
    get,
    draw,
    getPerRound
};

function draw() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return apiCall(`${config.apiUrl}/cup/draw`, requestOptions);
}

function get() {
    const requestOptions = {
        method: 'GET'
    };

    return apiCall(`${config.apiUrl}/cup`, requestOptions);
}

function getPerRound(roundNummer: number) {
    const requestOptions = {
        method: 'GET'
    };

    return apiCall(`${config.apiUrl}/cup/${roundNummer}`, requestOptions);
}
